import { React, useState, useEffect } from 'react'
import { useLocation } from "react-router-dom";
import axios from '../axios';
import { SearchOutlined } from '@ant-design/icons';
import { ChangeShopTypeName } from "services/changeData";
import { currencyFormat } from 'services/currencyFormat';
import moment from 'moment';
import { Image, Button, Table, Empty, Spin } from 'antd';
import { MdReceipt, MdShare, MdMap, MdPerson, MdPhone, MdStoreMallDirectory } from "react-icons/md";


function ProfilePage() {

  const search = useLocation().search;
  const query2 = new URLSearchParams(search).get('search');
  const query = (query2 === '' || query2 === null || query2 === 'null') ? "" : query2


  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingImage, setIsLoadingImage] = useState(false)
  const [isLoadingSale, setIsLoadingSale] = useState(false)
  const [searchTerm, setSearchTerm] = useState('');
  const [dataProfile, setDataProfile] = useState([])


  const [dataSale, setDataSale] = useState([])
  const dataProfileLength = (dataProfile.length === 0 || dataProfile === undefined) ? '' : dataProfile.data
  const dataProfileEcom = (dataSale.length === 0 || dataSale === undefined) ? '' : dataSale.ecom
  const dataProfileSku = (dataSale.length === 0 || dataSale === undefined) ? '' : dataSale.sku
  const dataShopID = (dataProfile.length === 0 || dataProfile === undefined) ? '' : dataProfileLength.ShopID


  const [dataProfileImage, setDataProfileImage] = useState([])
  const dataProfileImageLength = (dataProfileImage.length === 0 || dataProfileImage === undefined) ? '' : dataProfileImage.data

  const sumSalesIncVAT = (dataProfileEcom.length === 0 || dataProfileEcom === undefined) ? '' : dataProfileEcom.reduce((sum, number) => {
    const updatedSum = sum + number.SalesIncVat;
    return updatedSum;
  }, 0);


  // const {
  //   OwnerImg, //"https://api.tanjaiapp.com/Statics/ShopImages/2021/8/212a2ec7-1f69-458e-b875-602aa0b92724/2b49828c-9bca-40e5-b5ad-eb5d11e4c266.jpg"
  //   ShopImgNear, //"https://api.tanjaiapp.com/Statics/ShopImages/2021/8/212a2ec7-1f69-458e-b875-602aa0b92724/b8a498c5-3305-49d7-9fab-648100415ed1.jpg"
  //   ShopImgFar, //"https://api.tanjaiapp.com/Statics/ShopImages/2021/8/212a2ec7-1f69-458e-b875-602aa0b92724/100d368d-6f06-4c36-9d4e-98b47eea54a4.jpg"
  //   ShopCounterImg, //"https://api.tanjaiapp.com/Statics/ShopImages/2021/8/212a2ec7-1f69-458e-b875-602aa0b92724/fe47ed8f-495a-40cc-bdec-f53b39663768.jpg"

  // } = dataProfileImageLength


  const {

    ShopID, //"03-100495"
    DIST_NAME, //"หจก. เอ อาร์ ซี นอร์ทอีสเทิร์น"
    // ShopAge, //null
    ShopOwner, //null
    ShopName, //"ร้านอนุชาพาณิชย์"
    ContactName, //"นายอนุชา อุนทะวงษ์"
    IDCardNo, //""
    TaxNo, //""
    Latitude, //16.465927
    Longitude, //102.672196
    Phone1, //"0971697492"
    Phone2, //"0971697492"
    ShopAddress, //"85ม.18"
    SubDistrict, //"บ้านทุ่ม"
    District, //"เมืองขอนแก่น"
    Province, //"ขอนแก่น"
    ZipCode, //"40000"
    // Region, //"ภาคตะวันออกเฉียงเหนือ"
    LineUID, //"U1c7c641c6a19de91996e3367704f9ba0"
    ShopTypeNameId, //"106"
    ShopUpdate, //"20210811"
    Shopdailysales, //null
    countfollow //1

  } = dataProfileLength


  if (IDCardNo === undefined || TaxNo === undefined || IDCardNo === null || TaxNo === null || IDCardNo === "" || TaxNo === "") {

    // var IDCardNox = "";
    // var TaxNox = "";

  } else {
    let idcardnoStart = IDCardNo.slice(0, 2);
    let idcardnoEnd = IDCardNo.slice(11, 13);
    var IDCardNox = idcardnoStart + "*********" + idcardnoEnd;

    let idcardStart = TaxNo.slice(0, 2);
    let idcardEnd = TaxNo.slice(11, 13);
    var TaxNox = idcardStart + "*********" + idcardEnd;
  }


  if (Phone1 === undefined || Phone2 === undefined || Phone1 === null || Phone2 === null || Phone1 === "" || Phone2 === "") {

    // var Phone1x = "";
    // var idcPhone2xardx = "";

  } else {
    let phone1Start = Phone1.slice(0, 3);
    let phone1End = Phone1.slice(7, 10);
    var Phone1x = phone1Start + "****" + phone1End;

    let phone2Start = Phone2.slice(0, 3);
    let phone2End = Phone2.slice(7, 10);
    var Phone2x = phone2Start + "****" + phone2End;
  }

  if (LineUID === undefined || LineUID === null || LineUID === "") {

    // var LineUIDx = "";

  } else {
    let uidStart = LineUID.slice(0, 6);
    var LineUIDx = uidStart + "*******";
  }

  const profilelat = Latitude
  const profilelong = Longitude
  const shoplo = `${profilelat},${profilelong}`


  var diff = moment(ShopUpdate).diff(moment(), 'milliseconds');
  var duration = moment.duration(diff);
  var years = duration.years(); //-1
  var months = duration.months(); //-4
  var days = duration.days();// -30
  const timeregister = `${(Math.abs(years) === 0) ? "" : Math.abs(years) + " ปี "} ${(Math.abs(months) === 0) ? "" : Math.abs(months) + " เดือน "} ${(Math.abs(days) === 0) ? "" : Math.abs(days) + " วัน"}`


  function handleSubmit(e) {
    e.preventDefault();
    // Handle the search logic here
    window.open('/profile?search=' + searchTerm, '_blank', 'noreferrer');
    setSearchTerm('')
  }

  function checkImage(from) {
    let result = '';
    if (dataProfileImage.length === 0) {
      // alert(1);
      return <img src="./images/nophoto.png" width="100%" alt="" />
    } else {
      // alert(2);
      switch (from) {
        case "Profile":
          console.log('dataProfileImage', dataProfileImage);
          if (dataProfileImage.data.OwnerImg === null || dataProfileImage.data.OwnerImg === "" || dataProfileImage.data.OwnerImg === undefined) {
            if (dataProfileImage.data.ShopImgNear === null || dataProfileImage.data.ShopImgNear === "" || dataProfileImage.data.ShopImgNear === undefined) {
              result = <img src="./images/nophoto.png" width="100%" alt="" />
            } else {
              result = <img src={dataProfileImage.data.ShopImgNear} alt="" />
            }
          }
          else {
            result = <Image src={dataProfileImage.data.OwnerImg} alt="" />
          }
          break;
        case "ShopImgFar":
          if (dataProfileImage.data.ShopImgFar === null || dataProfileImage.data.ShopImgFar === "" || dataProfileImage.data.ShopImgFar === undefined) {
            result = <img src="./images/nophoto.png" width="100%" alt="" />
          } else {
            result = <Image src={dataProfileImage.data.ShopImgFar} alt="" />
          }
          break;
        case "ShopImgNear":
          if (dataProfileImage.data.ShopImgNear === null || dataProfileImage.data.ShopImgNear === "" || dataProfileImage.data.ShopImgNear === undefined) {
            result = <img src="./images/nophoto.png" width="100%" alt="" />
          } else {
            result = <Image src={dataProfileImage.data.ShopImgNear} alt="" />
          }
          break;
        case "ShopCounterImg":
          if (dataProfileImage.data.ShopCounterImg === null || dataProfileImage.data.ShopCounterImg === "" || dataProfileImage.data.ShopCounterImg === undefined) {
            result = <img src="./images/nophoto.png" width="100%" alt="" />
          } else {
            result = <Image src={dataProfileImage.data.ShopCounterImg} alt="" />
          }
          break;
        case "OwnerImg":
          if (dataProfileImage.data.OwnerImg === null || dataProfileImage.data.OwnerImg === "" || dataProfileImage.data.OwnerImg === undefined) {
            result = <img src="./images/nophoto.png" width="100%" alt="" />
          } else {
            result = <Image src={dataProfileImage.data.OwnerImg} alt="" />
          }
          break;
        case "UrlImage":
          if (dataProfileImage.data.ShopImgFar === null || dataProfileImage.data.ShopImgFar === "" || dataProfileImage.data.ShopImgFar === undefined) {
            result = ''
          } else {
            result = `url(${dataProfileImage.data.ShopImgFar})`
          }
          break;
        default:
        // code block
      }

      return result;
    }
  }


  useEffect(() => { //get data input
    const getdata = () => {


      (async () => {
        setIsLoading(true)
        // await axios.post(`api/qualitydata2/`,
        axios.post(`/api/shopprofile`,
          {
            id: query

          }).then((response) => {

            setDataProfile(response.data)
            setIsLoading(false)
          }).catch(function (error) {
            console.log('error.response', error);
          });
      })();


    }

    getdata();


  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => { //get data input
    const getdata = () => {
      (async () => {
        setIsLoadingImage(true);
        setIsLoadingSale(true);

        
        await axios.post(`/api/shopsaleImage`,
          {
            id: query

          }).then((response) => {

            // console.log('shopsaleImage', response);

            if (response.data.data) {
              setDataProfileImage(response.data)
            } else {
              setDataProfileImage([])
            }
            setIsLoadingImage(false);

          }).catch(function (error) {
            setIsLoadingImage(false);
            console.log('error.response', error);
          });

        await axios.post(`/api/shopsale`,
          {
            // id: dataShopID
            id: query

          }).then((response) => {
            setDataSale(response.data);
            setIsLoadingSale(false);

          }).catch(function (error) {
            setIsLoadingSale(false);
            console.log('error.response', error.response);
          });

      })();
    }
    (query !== '') && getdata();
 

  }, [query]) // eslint-disable-line react-hooks/exhaustive-deps



  // let size = "small"
  const columnsAll = [
    {
      title: 'เลขการสั่งซื้อ',
      dataIndex: 'orderID',
      width: '15%',
    },
    {
      title: 'วันที่สั่งซื้อ',
      dataIndex: 'orderTime',
      width: '15%',
      sorter: {
        compare: (a, b) => a.orderTime - b.orderTime,
        multiple: 3,
      },
    },
    {
      title: 'สินค้า',
      dataIndex: 'productNo',
      width: '62%',
    },
    {
      title: 'ยอดรวม',
      dataIndex: 'amount',
      fixed: 'right',
      width: '8%',
      sorter: {
        compare: (a, b) => a.amountSort - b.amountSort,
        multiple: 1,
      },
    },

  ];


  const catalogAll = [];
  const dataProfileEcomx = (dataSale.length === 0 || dataSale === undefined) ? '' : dataProfileEcom.map((ey1, index) => {
    let catall = {
      index: index,
      key: ey1.OrderId,
      orderID: ey1.OrderId,
      orderTime: ey1.OrderDate,
      productNo: (
        dataProfileSku.filter(eve => eve.OrderId === ey1.OrderId).map((eve, index) => {
          return (<div key={index}>-{eve.ProductName} - {eve.Qty}ชิ้น ({currencyFormat(eve.TotalInVat)}) บาท<br /></div>)
        })
      ),
      amount: currencyFormat(ey1.SalesIncVat),
      amountSort: ey1.SalesIncVat
    };
    return catalogAll.push(catall);
  });


  console.log((dataProfileEcomx !== '') ? '' : '');



  return (
    <div id="profile">


      <Spin spinning={isLoading ? true : false}></Spin>

      <div className='fixed-top d-flex flex-column'>
        <nav className="navbar navbar-expand-lg navbar-light  " style={{ borderRadius: '0', backgroundColor: '#ffffff' }}>
          <div className="container-fluid">
            <a className="navbar-brand" href="/" target='_blank'><img src='./images/b52logo.jpg' width="30px" alt="" /></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  {/* <a className="nav-link active" aria-current="page" href="#/">B2B</a> */}
                </li>
                <li className="nav-item">
                  {/* <a className="nav-link" href="#/">B2C</a> */}
                </li>
                <li className="nav-item">
                  {/* {
                    (isLoading) ? <span className="nav-link">กำลังโหลด...</span> : " "

                  } */}
                </li>
              </ul>
              <form onSubmit={(e) => handleSubmit(e)} className="d-flex gap-1">
                <input
                  type="search"
                  placeholder="รหัสร้านค้า หรือ เบอร์โทรศัพท์"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  // disabled={isLoading ? true : false}
                  className="form-control search"
                />
                <button className="btn btn-primary" type="submit" style={{ minWidth: '32px', borderRadius: '50%' }} disabled={(searchTerm === '') ? true : false}><SearchOutlined /></button>
              </form>
            </div>
          </div>
        </nav>
        <div className="container-fluid" style={{ padding: '0' }}>
          <div className="card text-dark" style={{ borderRadius: '0', backgroundColor: '#E6E6E6', border: 'none' }}>
            {/* <div className="card-header">ค้นหาโดย</div> */}
            <div className="card-body " style={{ padding: '0.5rem' }}>
              <label className="card-title num-text" style={{ marginBottom: '0' }}>{ShopName}</label>
            </div>
          </div>
        </div>
      </div>
      <div className='h-top-profile'></div>

      {

        (query === '' || query === null || query === 'null') ? <Empty /> : <>

          <div className="container-fluid">

            <div className="row  g-2 mt-2 alert alert-secondary "

              style={{
                backgroundImage: checkImage("UrlImage"),//`url(${dataProfileImage.data.ShopImgFar})`,
                objectFit: 'cover',
                objectPosition: 'center',
                padding: '1em',
              }}

            >
              <div className="overlay"></div>
              <div className="col-12 col-md-4 text-center" style={{ alignSelf: 'center' }}>
                <div className="card" style={{ background: "none", border: "none" }}>
                  <div className='img-profile'>
                    {
                      isLoadingImage ?
                        <div>
                          <div class="spinner-border text-light" role="status">
                            <span class="visually-hidden">Loading...</span>
                          </div>
                        </div> :
                        <Image.PreviewGroup>
                          {
                            checkImage("Profile")
                            // (OwnerImg === null || OwnerImg === "" || OwnerImg === undefined) ? 
                            // (
                            //   (ShopImgNear === null || ShopImgNear === "" || ShopImgNear === undefined) 
                            //   ? <img src="./images/nophoto.png" width="100%" alt="" /> : <img src={ShopImgNear} alt="" />
                            // ) : <Image src={OwnerImg} alt="" />
                          }
                        </Image.PreviewGroup>
                    }
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card ">
                  <div className="card-header" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    <MdPerson />ข้อมูลเจ้าของร้าน
                  </div>
                  <div className="card-body" >
                    <div className="d-flex justify-content-between mb-1">
                      <div>ชื่อ-นามสกุล :</div><div>{(ContactName === null || ContactName === "") ? "-" : ContactName}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>เพศ :</div><div>-</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>อายุ :</div><div>-</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>เลขประจำตัวประชาชน :</div><div>{(IDCardNo === null || IDCardNo === "" || IDCardNo === "0" || IDCardNo === 0) ? "-" : IDCardNox}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>เลขประจำตัวผู้เสียภาษี :</div><div>{(TaxNo === null || TaxNo === "" || TaxNo === 0 || TaxNo === "0") ? "-" : TaxNox}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>ประเภทภาษี :</div><div>-</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>เป็นเจ้าของร้านเอง หรือ เช่าสถานที่ :</div><div>{ShopOwner ? (ShopOwner === 1 ? 'เจ้าของร้าน' : 'เช่าสถานที่') : "-"}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>เครือข่ายโทรศัพท์ :</div><div>-</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <div className="card ">
                  <div className="card-header" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    <MdPhone />ข้อมูลติดต่อ
                  </div>
                  <div className="card-body" >
                    <div className="d-flex justify-content-between mb-1">
                      <div>เบอร์โทรศัพท์มือถือ 1 :</div><div>{(Phone1 === null || Phone1 === "") ? "-" : Phone1x}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>เบอร์โทรศัพท์มือถือ 2 :</div><div>{(Phone2 === null || Phone2 === "") ? "-" : Phone2x}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>เบอร์แฟกซ์ :</div><div>-</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>อีเมล :</div><div>-</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>Line id :</div><div>-</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>Line uid :</div><div>{(LineUID === null || LineUID === "") ? "-" : LineUIDx}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>Facebook :</div><div>-</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>ที่อยู่ :</div><div>{(ShopAddress === null || ShopAddress === "") ? "-" : ShopAddress}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row g-2 alert alert-secondary mt-2 mb-0 p-1">
              <div className="col-12 col-md-4">
                <div className="card ">
                  <div className="card-header" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    <MdStoreMallDirectory />ข้อมูลร้านค้า
                  </div>
                  <div className="card-body" >
                    <div className="d-flex justify-content-between mb-1">
                      <div>รหัสร้าน :</div><div>{(ShopID === null || ShopID === "") ? "-" : ShopID}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>ชื่อร้านค้า :</div><div>{(ShopName === null || ShopName === "") ? "-" : ShopName}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>ประเภทร้านค้า :</div><div>{(ShopTypeNameId === null || ShopTypeNameId === "") ? "-" : ChangeShopTypeName(ShopTypeNameId)}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>ชื่อดิสทริบิวเตอร์ :</div><div>{(DIST_NAME === null || DIST_NAME === "") ? "-" : DIST_NAME}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>เวลาเปิด-ปิด :</div><div>-</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>ระยะเวลาที่ร้านค้าเป็นสมาชิก TJD :</div><div>{(timeregister === null || timeregister === "") ? "-" : timeregister}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>ยอดขายร้านค้าเฉลี่ยต่อเดือน :</div><div>{Shopdailysales === "0" || Shopdailysales == null ? "-" : `${currencyFormat(Shopdailysales * 30)}`}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>จำนวนลูกค้าของร้านค้า :</div><div>{(countfollow === null || countfollow === "" || countfollow === 0) ? "-" : countfollow}</div>
                    </div>
                    <div className="d-flex justify-content-between mb-1">
                      <div>สินค้าขายดี :</div><div>-</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-8">
                <div className="card">
                  <div className="card-header" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                    <MdStoreMallDirectory />รูปภาพ
                  </div>

                  <div className="gallery-shop" style={{ marginTop: '1em' }}>
                    <Image.PreviewGroup>
                      <div style={{ padding: '0 6px', textAlign: 'center', alignSelf: 'center' }}>
                        {
                          isLoadingImage ?
                            <div>
                              <div class="spinner-border text-secondary" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div> :
                            <div className=" img-sub-profile">
                              {
                                checkImage("ShopImgFar")
                                // (ShopImgFar === null || ShopImgFar === "" || ShopImgFar === undefined) 
                                // ? <img src="./images/nophoto.png" width="100%" alt="" /> 
                                // : <Image src={ShopImgFar} alt="" />
                              }
                            </div>
                        }
                      </div>
                      <div style={{ padding: '0 6px', textAlign: 'center', alignSelf: 'center' }}>
                        {
                          isLoadingImage ?
                            <div>
                              <div class="spinner-border text-secondary" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div> :
                            <div className=" img-sub-profile">
                              {
                                checkImage("ShopImgNear")
                                // (ShopImgNear === null || ShopImgNear === "" || ShopImgNear === undefined) 
                                // ? <img src="./images/nophoto.png" width="100%" alt="" /> 
                                // : <Image src={ShopImgNear} alt="" />}
                              }
                            </div>
                        }
                      </div>
                      <div style={{ padding: '0 6px', textAlign: 'center', alignSelf: 'center' }}>
                        {
                          isLoadingImage ?
                            <div>
                              <div class="spinner-border text-secondary" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div> :
                            <div className=" img-sub-profile">
                              {
                                checkImage("ShopCounterImg")
                                // (ShopCounterImg === null || ShopCounterImg === "" || ShopCounterImg === undefined) 
                                // ? <img src="./images/nophoto.png" width="100%" alt="" /> 
                                // : <Image src={ShopCounterImg} alt="" />
                              }
                            </div>
                        }
                      </div>
                      <div style={{ padding: '0 6px', textAlign: 'center', alignSelf: 'center' }}>
                        {
                          isLoadingImage ?
                            <div>
                              <div class="spinner-border text-secondary" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div> :
                            <div className=" img-sub-profile">
                              {
                                checkImage("OwnerImg")
                                // (OwnerImg === null || OwnerImg === "" || OwnerImg === undefined) 
                                // ? <img src="./images/nophoto.png" width="100%" alt="" /> 
                                // : <Image src={OwnerImg} alt="" />
                              }
                            </div>
                        }
                      </div>
                    </Image.PreviewGroup>
                  </div>
                  <div className="card-header" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", marginTop: '1em' }}>
                    <MdMap />สถานที่และเส้นทาง
                  </div>
                  <div className="row" style={{ padding: '1em' }}>
                    <div className="col-12 col-md-6">
                      <div className="mapouter">
                        <div className="gmap_canvas">
                          {(profilelat === null || profilelong === null) ? <div className="cropmap"><img src="./images/nomap.png" width="100%" alt="" /></div> :
                            <iframe title="google map" className="gmap_iframe" width="100%" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src={`https://maps.google.com/maps?width=600&height=600&hl=en&q=${shoplo}&t=&z=16&ie=UTF8&iwloc=B&output=embed`} alt="" ></iframe>
                          }
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-md-6">
                      <div className="box-profile-address">
                        <div className="profile-address">
                          <div>{ShopAddress}{" "}{SubDistrict}{" "}{District}{" จังหวัด "}{Province}{" "}{ZipCode}</div>
                        </div>
                        <div className="profile-address">
                          <div style={{ display: (profilelat === null || profilelong === null) ? "none" : "block" }}       >
                            <a href={`https://maps.google.com/maps?q=${shoplo}`} target="_blank" rel="noreferrer"><Button><MdMap />ดูเส้นทาง</Button></a>&nbsp;&nbsp;
                            <a href={`https://maps.google.com/maps?q=${shoplo}`} target="_blank" rel="noreferrer"><Button><MdShare />แชร์เส้นทาง</Button></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row g-2 alert alert-secondary mt-2 mb-2 p-1">
              <div className="col-12">
                {/* <div className="alert alert-secondary mt-2 mb-0" style={{ backgroundColor: '#E6E6E6' }} role="alert"> */}
                <MdReceipt />รายการสั่งซื้อ
                <div className="row g-2 mt-2">
                  <div className="col-12 col-md-12"></div>
                  <div className="col-6 col-md-6">

                    <div className="card text-center" >
                      <div className="card-header">
                        ยอดสั่งซื้อ
                      </div>
                      {
                        isLoadingSale ?
                          <div className="card-body">
                            <div class="spinner-border text-light" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div> :
                          <div className="card-body">
                            <label className="card-title num-text" >{dataProfile.length === 0 || dataProfile === undefined ? 0 : currencyFormat(sumSalesIncVAT)}</label>
                          </div>
                      }
                      {/* <div className="card-body">
                        <label className="card-title num-text" >{dataProfile.length === 0 || dataProfile === undefined ? 0 : currencyFormat(sumSalesIncVAT)}</label>
                      </div> */}
                      <div className="card-footer text-muted">
                        บาท
                      </div>
                    </div>
                  </div>
                  <div className="col-6 col-md-6">
                    <div className="card text-center">
                      <div className="card-header">
                        จำนวน
                      </div>
                      {
                        isLoadingSale ?
                          <div className="card-body">
                            <div class="spinner-border text-light" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </div> :
                          <div className="card-body">
                            <label className="card-title num-text">{dataProfile.length === 0 || dataProfile === undefined ? 0 : dataProfileEcom.length}</label>
                          </div>
                      }
                      <div className="card-footer text-muted">
                        ออเดอร์
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-12"></div>
                </div>
                <div>
                  <Table
                    loading={isLoadingSale}
                    columns={columnsAll}
                    dataSource={catalogAll}
                    pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                    scroll={{
                      x: 1000,
                    }}
                  />
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>

        </>
      }
    </div>
  )
}

export default ProfilePage