import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import 'antd/dist/antd.css';
import './App.css';
import MainPage from 'views/mainPage';
import ProfilePage from "views/profilePage";
import LoginPage from "views/loginPage";
import RegisterUser from "views/registerUser";
import NotFound from "views/notFound";
import { Empty } from 'antd';

function App() {


  const token = localStorage.getItem('login');
  // const tokenUser = localStorage.getItem('user');
  const roleUser = localStorage.getItem('role');
  // const status = localStorage.getItem('status');


  const expLocal = localStorage.getItem('exp');
  const expDate = new Date(expLocal);
  const dateNow = new Date();

  const logout = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("user");
    localStorage.removeItem("collap");
    localStorage.removeItem("lang");
    localStorage.removeItem("role");
    localStorage.removeItem("status");
    localStorage.removeItem("exp");

    if (expLocal !== null) {
      setTimeout(() => {
        window.location.href = "/";
      }, 10);
    }

  };


  const logoutLead = () => {
    localStorage.removeItem("login");
    localStorage.removeItem("user");
    localStorage.removeItem("collap");
    localStorage.removeItem("lang");
    localStorage.removeItem("role");
    localStorage.removeItem("status");
    localStorage.removeItem("exp");
    
    setTimeout(() => {
      window.location.href = "https://lead.tjdplatform.com/";
    }, 10);
  };




  if (token === "false" || token === null || expLocal === null) {

    logout();

    return <LoginPage />

  } else {

    if(roleUser === "LeadPlatform"){
      logoutLead();
    }
    
    if(dateNow.getTime() > expDate.getTime()){
      logout();
    }

  }



  return (
    <Router>
      <Routes>
        <Route path="/" exact element={roleUser === 'SearchOnly' ? <NotFound /> : <MainPage />} />
        <Route path="/profile" exact element={token === "false" ? <NotFound /> : <ProfilePage />} />
        <Route path="/account" exact element={roleUser === 'SUPERADMIN' ? <RegisterUser /> : <NotFound />} />
        <Route path="/login" exact element={<LoginPage />} />
        <Route
          path="*"
          element={
            <Empty
              style={{ marginTop: '2em' }}
              description={
                <span style={{ color: "rgba(0, 0, 0, 0.25)" }}>
                  404 Page not found
                </span>
              }
            />
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
