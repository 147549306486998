import { React, useState, useEffect } from 'react'
import axios from '../axios';
import { Form, Select, Button,Spin  } from 'antd';
import { ChangeNameTypeTH, ChangeNameType } from "services/changeData";
import { SearchOutlined } from '@ant-design/icons';

function SubMainPage({
  setDataAlls,
  setDataAllInputs,
  setDataAllInputFulls,
  setNewDataInputs,
  setNewDataAlls,
  setInputShopTypeNums,
  setIsLoadings,
  setIsLoadingInputs,
  setRadioBlanks,
  filterRegion,
  filterProvince,
  setFilterProvince,
  setFilterRegion

}) {


  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map(item => [item[key], item])).values()]
  }



  const [dataAllInput, setDataAllInput] = useState([]);
  const [dataAllInputFull, setDataAllInputFull] = useState([]);

  const [newDataInput, setNewDataInput] = useState([]);
  const result = newDataInput.length === 0 ? dataAllInput : newDataInput;
  // const result =  dataAllInput ;
  
  const [newDataAll, setNewDataAll] = useState([]);
  const [dataAll, setDataAll] = useState([]);
  const dataAllLength = (dataAll.length === 0 || dataAll === undefined) ? '' : newDataAll.length === 0 ? dataAll.data : newDataAll

  const [inputShopTypeNum, setInputShopTypeNum] = useState('');
  const [inputShopType, setInputShopType] = useState('');
  const [inputDist, setInputDist] = useState('');
  const [inputRegion, setInputRegion] = useState('');
  const [inputProvince, setInputProvince] = useState('');


  const [resetForm, setResetForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingInput, setIsLoadingInput] = useState(false)
  const [isCheckResult, setIsCheckResult] = useState(false)
  

  const [firstLoad, setFirstLoad] = useState(false)

  const onChangeInputShopTypeNum = (values) => { setInputShopTypeNum(values); }
  const onChangeInputShopType = (values) => { setInputShopType(values) }
  const onChangeInputDist = (values) => { setInputDist(values) }
  const onChangeInputRegion = (values) => { 
    alert('change region');
    setInputRegion(values); 
    setFilterRegion(values); 
    setInputProvince(''); 
    setFilterProvince(''); 
    if(dataAll && dataAll.data)
    {
      setNewDataAll(dataAll.data);
      setNewDataAlls(dataAll.data);
    }
  }
  const onSelectInputRegion = (values) => { 

    // console.log('select region',values);
    setInputRegion(values); 
    setFilterRegion(values); 
    setInputProvince(''); 
    setFilterProvince('');
    setInputShopTypeNums('')
    setInputShopTypeNum('')
    setInputShopType('')
    setInputDist('')
    if(dataAll && dataAll.data)
    {
      setNewDataAll(dataAll.data);
      setNewDataAlls(dataAll.data);
    }
  }
  const onChangeInputProvince = (values) => { 
    setInputProvince(values); 
    setFilterProvince(values);

    if(values)
    {
      if(dataAll && dataAll.data)
      {
        let filter = dataAll.data.filter(i => i.Province.toString().toLowerCase() == values.toString().toLowerCase());
        console.log('filter',filter[0]);
        setInputRegion(filter[0].Region);
      }
    }
  }

  useEffect(() => {
    // console.log('filterRegion',filterRegion);
    // console.log('filterProvince',filterProvince);
    // console.log('dataAll.data', dataAll.data);
    if(dataAll && dataAll.data)
    {
      setNewDataAll(dataAll.data);
      setNewDataAlls(dataAll.data);
    }

    if(filterRegion)
    {
      setInputRegion(filterRegion);
    }

      setInputProvince(filterProvince);
  
  }, [filterRegion, filterProvince]);

  useEffect(() => {

    const getdata = () => {
      
      const newDataInput = dataAll.length === 0 || dataAll === undefined ? '' : dataAllInput.filter(item => {
        return (
          (item.ShopTypeGroupNum.toString().toLowerCase().indexOf(inputShopTypeNum.toString().toLowerCase()) > -1 ||
            item.ShopTypeGroupNum.toString().indexOf(inputShopTypeNum === '6' ? 'X' : null) > -1 ||
            item.ShopTypeGroupNum.toString().indexOf(inputShopTypeNum === '6' ? 'Z' : null) > -1)
          &&
          // item.ShopTypeNameId.toString().toLowerCase().indexOf(inputShopType.toString().toLowerCase()) > -1 &&
          // item.saleunit.toString().toLowerCase().indexOf(inputDist.toString().toLowerCase()) > -1 &&
          // item.RegionId.toString().toLowerCase().indexOf(inputRegion.toString().toLowerCase()) > -1 &&
          // item.ProvinceId.toString().toLowerCase().indexOf(inputProvince.toString().toLowerCase()) > -1

          item.ShopTypeName.toString().toLowerCase().indexOf(inputShopType.toString().toLowerCase()) > -1 &&
          item.DIST_NAME.toString().toLowerCase().indexOf(inputDist.toString().toLowerCase()) > -1 &&
          // item.Region.toString().toLowerCase().indexOf(inputRegion.toString().toLowerCase()) > -1 &&
          (inputRegion != '' ? item.Region.toString().toLowerCase() == inputRegion.toString().toLowerCase() : item.Region) &&
          item.Province.toString().toLowerCase().indexOf(inputProvince.toString().toLowerCase()) > -1
        );
      });
      // console.log('newDataInput',newDataInput);
      setNewDataInput(newDataInput);
      setNewDataInputs(newDataInput);
      setDataAllInputFull(dataAllInputFull);
      setInputShopTypeNums(inputShopTypeNum);
      setRadioBlanks(false)

      // console.log('dataAllLength',dataAllLength);
      const newDataAll = dataAll.length === 0 || dataAll === undefined ? '' : dataAllLength.filter(item => {
        return (
          (item.ShopTypeGroupNum.toString().toLowerCase().indexOf(inputShopTypeNum.toString().toLowerCase()) > -1 ||
            item.ShopTypeGroupNum.toString().indexOf(inputShopTypeNum === '6' ? 'X' : null) > -1 ||
            item.ShopTypeGroupNum.toString().indexOf(inputShopTypeNum === '6' ? 'Z' : null) > -1)
          &&
          // item.ShopTypeNameId.toString().toLowerCase().indexOf(inputShopType.toString().toLowerCase()) > -1 &&
          // item.saleunit.toString().toLowerCase().indexOf(inputDist.toString().toLowerCase()) > -1 &&
          // item.RegionId.toString().toLowerCase().indexOf(inputRegion.toString().toLowerCase()) > -1 &&
          // item.ProvinceId.toString().toLowerCase().indexOf(inputProvince.toString().toLowerCase()) > -1

          item.ShopTypeName.toString().toLowerCase().indexOf(inputShopType.toString().toLowerCase()) > -1 &&
          item.DIST_NAME.toString().toLowerCase().indexOf(inputDist.toString().toLowerCase()) > -1 &&
          // item.Region.toString().toLowerCase().indexOf(inputRegion.toString().toLowerCase()) > -1 &&
          (inputRegion != '' ? item.Region.toString().toLowerCase() == inputRegion.toString().toLowerCase() : item.Region) &&
          item.Province.toString().toLowerCase().indexOf(inputProvince.toString().toLowerCase()) > -1

        );
      });
      console.log('newDataAll',newDataAll);
      setNewDataAll(newDataAll);
      setNewDataAlls(newDataAll);
      

    }
    (inputShopTypeNum !== '' || inputShopType !== '' || inputDist !== '' || inputRegion !== '' || inputProvince !== '') && getdata();

  }, [inputShopTypeNum, inputShopType, inputDist, inputRegion, inputProvince]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => {  // BTN resetForm

    const getdata = () => {
      setNewDataInput(dataAllInput);
      setNewDataInputs(dataAllInput);
      setNewDataAll(dataAll.data);
      setNewDataAlls(dataAll.data);
      setResetForm(false)
    }
    (resetForm === true) && getdata();

  }, [resetForm]) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => { //get data input
    const getdata = () => {
      (async () => {
        setIsLoadingInput(true)
        setIsLoadingInputs(true)
        // await axios.post(`api/qualitydata2/`,
        axios.post(`/api/selectinput`,
          {
            // radiotype: radiotype,
            inputShopTypeNum: inputShopTypeNum,
            inputShopType: inputShopType,
            inputDist: inputDist,
            inputRegion: inputRegion,
            inputProvince: inputProvince

          }).then((response) => {
            let result = response.data.data
            // let [{ dataResults: dataResults }] = result

            setDataAllInput(result[0].dataResults)
            setDataAllInputs(result[0].dataResults)
            setDataAllInputFull(result[0].dataResults)
            setDataAllInputFulls(result[0].dataResults)
            setIsCheckResult((inputShopTypeNum !== '' || inputShopType !== '' || inputDist !== '' || inputRegion !== '' || inputProvince !== '') ? false : true)
            setIsLoadingInput((inputShopTypeNum !== '' || inputShopType !== '' || inputDist !== '' || inputRegion !== '' || inputProvince !== '') ? false : true)
            setIsLoadingInputs((inputShopTypeNum !== '' || inputShopType !== '' || inputDist !== '' || inputRegion !== '' || inputProvince !== '') ? false : true)
            // setCounter((checkResetForm === true && (inputShopTypeNum !== '' || inputShopType !== '' || inputDist !== '' || inputRegion !== '' || inputProvince !== '') ) ? 0 : (checkResetForm === true ) ? 6 : 8)
            setResetForm(false)
            setFirstLoad(true)
          }).catch(function (error) {
            console.log('error.response', error.response);
          });
      })();
    }
    getdata();

  }, []) // eslint-disable-line react-hooks/exhaustive-deps


  useEffect(() => { //get data all

    const getdata = () => {
      (async () => {
        setIsLoading(true)
        setIsLoadings(true)
        // await axios.post(`api/qualitydata2/`,
        axios.post(`/api/dataall`,
          {
            // radiotype: radiotype,
            inputShopTypeNum: inputShopTypeNum,
            inputShopType: inputShopType,
            inputDist: inputDist,
            inputRegion: inputRegion,
            inputProvince: inputProvince

          }).then((response) => {

            setDataAll(response.data)
            setDataAlls(response.data)
            setResetForm(false)
            // setCheckOnFinish(false)
            setIsLoading(false)
            setIsLoadings(false)
            setIsLoadingInput(false)
            setIsLoadingInputs(false)
            setIsCheckResult(false)
            setFirstLoad(false)
            // setCheckBtnShowRadio(true)
            // setCheckBtnShowRadio((inputShopTypeNum !== '' || inputShopType !== '' || inputDist !== '' || inputRegion !== '' || inputProvince !== '') ? true : false)

            // setCounter(0)
          }).catch(function (error) {
            setIsLoading(false)
            setIsLoadings(false)
            setIsLoadingInput(false)
            setIsLoadingInputs(false)
            console.log('error.response', error.response);
          });
      })();
    }

    (isCheckResult === true && firstLoad === true) && getdata();
    // getdata()

    // }, [checkOnFinish, isCheckResult, resetForm]) // eslint-disable-line react-hooks/exhaustive-deps
  }, [isCheckResult]) // eslint-disable-line react-hooks/exhaustive-deps


  // const API_URL = '/api/selectinput'
  // const { getDatas } = UseFetchMain(API_URL);
  // const result = getDatas.data;


  const getShopTypeGroupNum = result === undefined ? '' : getUniqueListBy(result, 'ShopTypeGroupNum') //X, Z
  // console.log('getShopTypeGroupNum',getShopTypeGroupNum);
  const itemsShopTypeGroupNum = getShopTypeGroupNum === '' ? '' : getShopTypeGroupNum.map(items => items.ShopTypeGroupNum).sort();
  const itemsShopTypeGroupNumFilter = getShopTypeGroupNum === '' ? '' : itemsShopTypeGroupNum.filter(item => (item !== 'X' && item !== 'Z'));

  // const getShopTypeName = result === undefined ? '' : getUniqueListBy(result, 'ShopTypeNameId') //XXX, ZT
  // const itemsShopTypeName = getShopTypeName === '' ? '' : getShopTypeName.map(items => items.ShopTypeNameId).sort();
  // const itemsShopTypeNameFilter = getShopTypeName === '' ? '' : itemsShopTypeName.filter(item => (item !==  'XXX' && item !==  'ZT' ) );
  const getShopTypeName = result === undefined ? '' : getUniqueListBy(result, 'ShopTypeName')
  const itemsShopTypeName = getShopTypeName === '' ? '' : getShopTypeName.map(items => items.ShopTypeName).sort();
  const itemsShopTypeNameFilter = getShopTypeName === '' ? '' : itemsShopTypeName.filter(item => (item !== 'กรุณาบันทึกข้อมูล'));


  // const getDistName = result === undefined ? '' : getUniqueListBy(result, 'saleunit')
  // const itemsDistName = getDistName === '' ? '' : getDistName.map(items => items.saleunit)
  const getDistName = result === undefined ? '' : getUniqueListBy(result, 'DIST_NAME')
  const itemsDistName = getDistName === '' ? '' : getDistName.map(items => items.DIST_NAME)



  // const getRegion = result === undefined ? '' : getUniqueListBy(result, 'RegionId')
  // const itemsRegion = getRegion === '' ? '' : getRegion.map(items => items.RegionId)
  const getRegion = dataAllInput === undefined ? '' : getUniqueListBy(dataAllInput, 'Region')
  const itemsRegion = getRegion === '' ? '' : getRegion.map(items => items.Region)



  // const getProvince = result === undefined ? '' : getUniqueListBy(result, 'ProvinceId')
  // const itemsProvince = getProvince === '' ? '' : getProvince.map(items => items.ProvinceId)
  const getProvince = result === undefined ? '' : getUniqueListBy(result, 'Province')
  const itemsProvince = getProvince === '' ? '' : getProvince.map(items => items.Province)



  const { Option } = Select;
  const [form] = Form.useForm();

  const onFinish = (values) => {
    // getdataresult();
    // setCheckOnFinish(true)
    setIsCheckResult(true)
    // setCheckBtnClear(true)
    // setCheckBtnShowRadio(false)
    // setCounter((inputShopTypeNum !== '' || inputShopType !== '' || inputDist !== '' || inputRegion !== '' || inputProvince !== '' ) ? 6 : 0)
  };

  const onFinishFailed = (errorInfo) => {
    console.log('errorInfo:', errorInfo);
  }

  const onClearFilter = () => {

    form.resetFields();
    setInputShopTypeNums('')
    setInputShopTypeNum('')
    setInputShopType('')
    setInputDist('')
    setInputRegion('')
    setInputProvince('')
    setResetForm(true)
    setRadioBlanks(true)
    setFilterProvince('')
    setFilterRegion('')

    // setCheckBtnClear(false)
  }



  const [searchTerm, setSearchTerm] = useState('');

  function handleSubmit(e) {
    e.preventDefault();
    // Handle the search logic here
    window.open('/profile?search=' + searchTerm, '_blank', 'noreferrer');
    setSearchTerm('')
  }


  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light  " style={{ borderRadius: '0', backgroundColor: '#ffffff' }}>
        <div className="container-fluid">
          <a className="navbar-brand" href="/" target='_blank'><img src='./images/b52logo.jpg' width="30px" alt=""  /></a>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                {/* <a className="nav-link active" aria-current="page" href="#/">B2B</a> */}
              </li>
              <li className="nav-item">
                {/* <a className="nav-link" href="#/">B2C</a> */}
              </li>
            </ul>
            <form onSubmit={(e) => handleSubmit(e)} className="d-flex gap-1">
              <input
                type="search"
                placeholder="รหัสร้านค้า หรือ เบอร์โทรศัพท์"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                disabled={isLoading || isLoadingInput ? true : false}
                className="form-control search"
              />
              <button className="btn btn-primary" type="submit" style={{minWidth: '32px',borderRadius: '50%'}} disabled={(searchTerm === '') ? true : false}><SearchOutlined /></button>
            </form>

          </div>
        </div>
      </nav>


      <div className="container-fluid" style={{ padding: '0' }}> 
        <div className="card text-dark" style={{ borderRadius: '0', backgroundColor: '#E6E6E6', border: 'none' }}>
          {/* <div className="card-header">ค้นหาโดย</div> */}
          <div className="card-body " style={{ padding: '0.5rem' }}>




          <Spin spinning={isLoading || isLoadingInput ? true : false}>

            <Form

              form={form}
              name="form-input"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}

            >
              <div className="row">
                <div className="col-12 ">
                  <div className="row g-2 ">
                    <div className="col-6 col-md-2">

                      <Form.Item>
                        <Select
                          showSearch
                          onChange={onChangeInputShopTypeNum}
                          value={(inputShopTypeNum === '') ? null : inputShopTypeNum}
                          placeholder={(itemsShopTypeGroupNum === '') ? 'กำลังโหลด...' : "ประเภทธุรกิจ"}
                          name='inputshoptypenum'
                          style={{ width: '100%' }}
                          disabled={isLoading || isLoadingInput ? true : false}
                        >
                          {
                            dataAllInput.length === 0 ? '' : itemsShopTypeGroupNumFilter !== '' && itemsShopTypeGroupNumFilter.map((items, index) => (<Option value={items} key={index}>{ChangeNameType(items)}</Option>))
                          }
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-6 col-md-2">
                      <Form.Item>
                        <Select
                          showSearch
                          onChange={onChangeInputShopType}
                          value={(inputShopType === '') ? null : inputShopType}
                          placeholder={(itemsShopTypeName === '') ? 'กำลังโหลด...' : "ประเภทร้านค้า"}
                          name='inputshoptype'
                          style={{ width: '100%' }}
                          disabled={isLoading || isLoadingInput ? true : false}
                        >
                          {
                            dataAllInput.length === 0 ? '' : itemsShopTypeNameFilter !== '' && itemsShopTypeNameFilter.map((items, index) => (<Option value={items} key={index}>{ChangeNameTypeTH(items)}</Option>))
                          }
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-6 col-md-3">
                      <Form.Item>
                        <Select
                          showSearch
                          onChange={onChangeInputDist}
                          value={(inputDist === '') ? null : inputDist}
                          placeholder={(itemsDistName === '') ? 'กำลังโหลด...' : "ดิสทริบิวเตอร์"}
                          name='inputdist'
                          style={{ width: '100%' }}
                          disabled={isLoading || isLoadingInput ? true : false}
                        >
                          {
                            dataAllInput.length === 0 ? '' : itemsDistName !== '' && itemsDistName.map((items, index) => (<Option value={items} key={index}>{items}</Option>))
                          }
                        </Select>
                      </Form.Item>
                    </div>

                    <div className="col-6 col-md-2">
                      <Form.Item>
                        <Select
                          showSearch
                          // onChange={onChangeInputRegion}
                          onSelect={onSelectInputRegion}
                          value={(inputRegion === '') ? null : inputRegion}
                          placeholder={(itemsRegion === '') ? 'กำลังโหลด...' : "ภาค"}
                          name='inputregion'
                          style={{ width: '100%' }}
                          disabled={isLoading || isLoadingInput ? true : false}
                        >
                          {
                            dataAllInput.length === 0 ? '' : itemsRegion !== '' && itemsRegion.map((items, index) => (<Option value={items} key={index}>{items}</Option>))
                          }
                        </Select>
                      </Form.Item>
                    </div>
                    <div className="col-6 col-md-2">
                      <Form.Item>
                        <Select
                          showSearch
                          onChange={onChangeInputProvince}
                          value={(inputProvince === '') ? null : inputProvince}
                          placeholder={(itemsProvince === '') ? 'กำลังโหลด...' : "จังหวัด"}
                          name='inputprovince'
                          style={{ width: '100%' }}
                          disabled={isLoading || isLoadingInput ? true : false}
                        >
                          {
                            dataAllInput.length === 0 ? '' : itemsProvince !== '' && itemsProvince.map((items, index) => (<Option value={items} key={index}>{items}</Option>))
                          }
                        </Select>
                      </Form.Item>
                    </div>

                    <div className="col-6 col-md-1 text-center">

                      {
                        // (checkBtnClear === false || (isLoading || isLoadingInput ? true : false) || (inputShopTypeNum === '' && inputShopType === '' && inputDist === '' && inputRegion === '' && inputProvince === '')) ?
                        ((isLoading || isLoadingInput ? true : false) || (inputShopTypeNum === '' && inputShopType === '' && inputDist === '' && inputRegion === '' && inputProvince === '')) ?

                          <Button type="submit" className="btn btn-secondary btn-sm" onClick={onClearFilter} disabled>ล้างการค้นหา</Button>
                          :
                          <Button type="submit" className="btn btn-secondary btn-sm" onClick={onClearFilter}>ล้างการค้นหา</Button>
                      }

                    </div>
                  </div>
                </div>

              </div>
            </Form>

</Spin>
          </div>
        </div>
      </div>
    </>
  )
}

export default SubMainPage