import { React, useState, useEffect } from 'react';
import { Table, Button, Empty, notification, Link } from 'antd'; 
import {currencyFormat, currencyFormat1} from 'services/currencyFormat';
import Chart from "react-apexcharts";

function BoxRegion({dataAll, setFilterProvince, setFilterRegion}) {



    const getUniquesRegions = dataAll.length === 0 ? '' : dataAll.map(e => e.Region);
    const getUniquesRegionss = dataAll.length === 0 ? '' : Array.from(new Set(getUniquesRegions)); //remove duplicate
    const getUniquesRegionsss = dataAll.length === 0 ? '' : getUniquesRegionss.map((key) => ({ Region: key })); //insertproverties
    
    const getUniquesProvinces = dataAll.length === 0 ? '' : dataAll.map(e => e.Province);
    const getUniquesProvincess = dataAll.length === 0 ? '' : Array.from(new Set(getUniquesProvinces)); //remove duplicate
    const getUniquesProvincesss = dataAll.length === 0 ? '' : getUniquesProvincess.map((key) => ({ Province: key })); //insertproverties

const clickRegion = (record) => {
    setFilterRegion(record.region)
    setFilterProvince('')
}
// start region
    const columnsRegion = [
        {
            title: 'ภาค',
            dataIndex: 'region',
            ellipsis: true,
            render: (text, record) => (
                // <div>{ record.region}</div>
                // <div><a type="button" href="#" onClick={() => setFilterRegion(record.region)}>{ record.region}</a></div>
                <div><a type="button" href="#" onClick={() => {
                    setFilterRegion(record.region);
                    setFilterProvince('')}}>{ record.region}</a></div>
                
            ),
        },
        {
            title: 'ร้านค้า',
            dataIndex: 'countShop',
        },

        {
            title: 'สัดส่วน(%)',
            dataIndex: 'percentShop',
        },
    ];
    const sortregion = dataAll.length === 0 ? '' : getUniquesRegionsss.sort((a, b) => (a.Region > b.Region) ? 1 : -1)
    const data = [];
    const region = dataAll.length === 0 ? '' :  sortregion.map(e => {
        let obj = {
            key: e.Region,
            region: e.Region,
            countShop: ([currencyFormat(dataAll.length === 0 ? '' : dataAll.filter(ev => ev.Region === e.Region).length)]),
            percentShop:
                ([currencyFormat1(Number(dataAll.filter(ev => ev.Region === e.Region).length) / Number(dataAll.filter(ev => ev.Region).length) * Number(100)) + "%"]),
        };
        return data.push(obj);
    });
    const dataRegion = JSON.stringify(data);
// end region

// start province
    const columnsProvince = [
        {
            title: 'จังหวัด',
            dataIndex: 'province',
            ellipsis: true,
            render: (text, record) => (
                // <div>{ record.province}</div>
                <div><a type="button" href="#" onClick={() => setFilterProvince(record.province)}>{ record.province}</a></div>
            ),
        },
        {
            title: 'ร้านค้า',
            dataIndex: 'countShop',
        },

        {
            title: 'สัดส่วน(%)',
            dataIndex: 'percentShop',
        },
    ];
    const sortprovince = dataAll.length === 0 ? '' : getUniquesProvincesss.sort((a, b) => (a.Province > b.Province) ? 1 : -1)
    const dataProvince = [];
    const province = dataAll.length === 0 ? '' :  sortprovince.map(e => {
        let obj = {
            key: e.Province,
            province: e.Province,
            countShop: ([currencyFormat(dataAll.length === 0 ? '' : dataAll.filter(ev => ev.Province === e.Province).length)]),
            percentShop:
                ([currencyFormat1(Number(dataAll.filter(ev => ev.Province === e.Province).length) / Number(dataAll.filter(ev => ev.Province).length) * Number(100)) + "%"]),
        };
        return dataProvince.push(obj);
    });
    const dataProvinces = JSON.stringify(dataProvince);
// end province


//chart

    const regionCount = dataAll.length === 0 ? '' : getUniquesRegionsss.map(e => {
        let obj = {
            key: e.Region,
            region: e.Region,
            countShop: (
                dataAll.filter(ev => ev.Region === e.Region).length

            )
        };
        return obj
    });

    const regionOnlys = dataAll.length === 0 ? '' : regionCount.map(e => e.region)
    const choose_region = dataAll.length === 0 ? '' : regionOnlys.filter(e => e);

    const provinceOnly = dataAll.length === 0 ? '' : regionCount.map(e => e.countShop)
    const choose_region_num = dataAll.length === 0 ? '' : provinceOnly;


    const chartdonut = {

        options: {
            chart: {
                id: "region",
                width: "100%",
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    // speed: 900,
                    dynamicAnimation: {
                        // enabled: false,
                        speed: 1000
                    }
                }

            },
            plotOptions: {
                pie: {
                    startAngle: 0,
                    endAngle: 360,
                    expandOnClick: true,
                    offsetX: 0,
                    offsetY: 0,
                    customScale: 1,
                    dataLabels: {
                        offset: 0,
                        minAngleToShowLabel: 10
                    },
                    donut: {
                        size: '65%',
                        background: 'transparent',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Prompt, sans-serif',
                                fontWeight: 600,
                                color: undefined,
                                offsetY: -10,
                                formatter: function (val) {
                                    return val
                                }
                            },
                            value: {
                                show: true,
                                fontSize: '22px',
                                fontFamily: 'Prompt, sans-serif',
                                fontWeight: 600,
                                color: undefined,
                                offsetY: 16,
                                formatter: function (val) {
                                    return currencyFormat(val)
                                }
                            },
                            total: {
                                show: true,
                                showAlways: false,
                                label: 'ร้านค้าทั้งหมด',
                                fontSize: '18px',
                                fontFamily: 'Prompt, sans-serif',
                                fontWeight: 600,
                                color: '#373d3f',
                                formatter: function (w) {
                                    return currencyFormat(w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b
                                    }, 0))
                                }
                            }
                        }
                    },
                }
            },

            dataLabels: {
                enabled: true
            },
            stroke: {
                width: 0,
                colors: ["#fff"]
            },

            labels: [...choose_region],
            // labels: [Region],

            colors: ['#2B76CA', '#F2994A', '#BB6BD9', '#FAD557', '#219653', '#EB5757'],
            tooltip: {
                enabled: false,
            },
            legend: {
                show: true,
                // showForSingleSeries: false,
                // showForNullSeries: true,
                // showForZeroSeries: true,
                position: 'bottom',
                horizontalAlign: 'center',
                // floating: false,
                // fontSize: '14px',
                fontFamily: 'Prompt, sans-serif',
                // fontWeight: 400,
                // formatter: undefined,
                // inverseOrder: false,
                // width: undefined,
                // height: undefined,
                // tooltipHoverFormatter: undefined,
                customLegendItems: [],
                offsetX: 0,
                offsetY: 0,
                labels: {
                    colors: undefined,
                    useSeriesColors: false
                }
            },

            // responsive: [
            //     {

            //         breakpoint: 1400,
            //         options: {
            //             chart: {
            //                 width: "100%",

            //             },
            //             plotOptions: {
            //                 pie: {
            //                     startAngle: 0,
            //                     endAngle: 360,
            //                     expandOnClick: true,
            //                     offsetX: 0,
            //                     offsetY: 0,
            //                     customScale: 1,
            //                     dataLabels: {
            //                         offset: 0,
            //                         minAngleToShowLabel: 10
            //                     },
            //                     donut: {
            //                         size: '65%',
            //                         background: 'transparent',
            //                         labels: {
            //                             show: true,
            //                             name: {
            //                                 show: true,
            //                                 fontSize: '11px',
            //                                 fontFamily: 'Prompt, sans-serif',
            //                                 fontWeight: 600,
            //                                 color: undefined,
            //                                 offsetY: -10,
            //                                 formatter: function (val) {
            //                                     return val
            //                                 }
            //                             },
            //                             value: {
            //                                 show: true,
            //                                 fontSize: '16px',
            //                                 fontFamily: 'Prompt, sans-serif',
            //                                 fontWeight: 400,
            //                                 color: undefined,
            //                                 offsetY: 6,
            //                                 formatter: function (val) {
            //                                     return val
            //                                 }
            //                             },
            //                             total: {
            //                                 show: true,
            //                                 showAlways: false,
            //                                 label: 'Total',
            //                                 fontSize: '22px',
            //                                 fontFamily: 'Prompt, sans-serif',
            //                                 fontWeight: 600,
            //                                 color: '#373d3f',
            //                                 formatter: function (w) {
            //                                     return w.globals.seriesTotals.reduce((a, b) => {
            //                                         return a + b
            //                                     }, 0)
            //                                 }
            //                             }
            //                         }
            //                     },
            //                 }
            //             },


            //         }

            //     },

            // ]
        },
        
        // series: [1108, 9974, 2116, 1306, 2490, 109],
        series: [...choose_region_num],



    }

    const provinceCount = dataAll.length === 0 ? '' : getUniquesProvincesss.map(ev => {
        let obj2 = {
            key: ev.Province,
            Province: ev.Province,
            countShop: (
                dataAll.filter(eve => eve.Province === ev.Province).length
            )
        };
        return obj2
    });

    const provinceGraph = dataAll.length === 0 ? '' : provinceCount.map(ev => ev.Province)
    const choose_province = dataAll.length === 0 ? '' : provinceGraph.filter(e => e);
    const shopGraph = dataAll.length === 0 ? '' : provinceCount.map(ev => ev.countShop)
    const choose_province_num = dataAll.length === 0 ? '' :  shopGraph;
    // const choose_province_num = (valueform1 === '' && valueform2 === '' && valueform3  === '' && valueform4  === '' && radiotype  === '') ? JSON.parse(province_num) : shopGraph;


    const chartdonutprovince = {

        options: {
            chart: {
                id: "province",
                width: "100%",
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    // speed: 900,

                    dynamicAnimation: {
                        // enabled: false,
                        speed: 1000
                    }
                }

            },
            plotOptions: {
                pie: {
                    startAngle: 0,
                    endAngle: 360,
                    expandOnClick: true,
                    offsetX: 0,
                    offsetY: 0,
                    customScale: 1,
                    dataLabels: {
                        offset: 0,
                        minAngleToShowLabel: 10
                    },
                    donut: {
                        size: '65%',
                        background: 'transparent',
                        labels: {
                            show: true,
                            name: {
                                show: true,
                                fontSize: '16px',
                                fontFamily: 'Prompt, sans-serif',
                                fontWeight: 600,
                                color: undefined,
                                offsetY: -10,
                                formatter: function (val) {
                                    return val
                                }
                            },
                            value: {
                                show: true,
                                fontSize: '20px',
                                fontFamily: 'Prompt, sans-serif',
                                fontWeight: 600,
                                color: undefined,
                                offsetY: 16,
                                formatter: function (val) {
                                    return currencyFormat(val)
                                }
                            },
                            total: {
                                show: true,
                                showAlways: false,
                                label: 'ร้านค้าทั้งหมด',
                                fontSize: '18px',
                                fontFamily: 'Prompt, sans-serif',
                                fontWeight: 600,
                                color: '#373d3f',
                                formatter: function (w) {
                                    return currencyFormat(w.globals.seriesTotals.reduce((a, b) => {
                                        return a + b
                                    }, 0))
                                }
                            }
                        }
                    },
                }
            },

            dataLabels: {
                enabled: true
            },
            stroke: {
                width: 0,
                colors: ["#fff"]
            },

            labels: [...choose_province],
            // labels: [Region],

            colors: ['#2B76CA', '#F2994A', '#BB6BD9', '#FAD557', '#219653', '#EB5757', '#5A2A27', '#F86624', '#00B1F2', '#662E9B', '#00E396', '	#546E7A'],
            tooltip: {
                enabled: false,
            },
            legend: {
                show: false,
            },
        },
        // series: [1108, 9974, 2116, 1306, 2490, 109],
        series: [...choose_province_num],



    }

    return (
        <>
            <div className="card">
                <div className="card-header">
                    <div className='d-flex justify-content-between align-items-center'>
                        <div>จำนวนร้านค้าแยกตาม (ภาค / จังหวัด)</div>
                        <ul className="nav" id="pills-tab" role="tablist">
                            <li className="nav-item dropdown" >
                                <a className="nav-link dropdown-toggle" data-bs-toggle="dropdown" href="/#" role="button" aria-expanded="false" style={{padding:0}}>เลือกประเภท</a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/#" id="pills-home-tab" data-bs-toggle="tab" data-bs-target="#pills-region" aria-controls="pills-region" >ภาค</a></li>
                                    <li><a className="dropdown-item" href="/#" id="pills-profile-tab" data-bs-toggle="tab" data-bs-target="#pills-province" aria-controls="pills-province">จังหวัด</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div id="box-region" className="card-body">
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-region" role="tabpanel" aria-labelledby="pills-home-tab">
                            <div className='d-flex flex-column'>
                                <div className="box-region-chart">
                                {
                                    dataAll.length === 0 ? '' : <Chart options={chartdonut.options} series={chartdonut.series} type="donut" width="100%" />
                                }
                                </div>
                                <div className="box-region-table">
                                                        <Table
                                                            columns={columnsRegion}
                                                            dataSource={JSON.parse(dataRegion)}
                                                            // pagination={{ defaultPageSize: 10, showSizeChanger: false, pageSizeOptions: ['10', '20', '30'] }}
                                                            pagination={false}
                                                        />
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-province" role="tabpanel" aria-labelledby="pills-profile-tab">
                            <div className='d-flex flex-column'>
                                <div className="box-region-chart">
                                    {
                                        <Chart options={chartdonutprovince.options} series={chartdonutprovince.series} type="donut" width="100%" />
                                    }
                                </div>
                                <div className="box-region-table">
                                                        <Table
                                                            columns={columnsProvince}
                                                            dataSource={JSON.parse(dataProvinces)}
                                                            // pagination={{ defaultPageSize: 10, showSizeChanger: false, pageSizeOptions: ['10', '20', '30'] }}
                                                            pagination={true}
                                                        />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BoxRegion