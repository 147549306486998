import React from 'react'
import { Empty } from 'antd';

function NotFound() {
  return (
    <Empty
      style={{ marginTop: '2em'}}
      description={
        <span style={{color:"rgba(0, 0, 0, 0.25)"}}>
          404 Page not found
        </span>
      }
    />
  )
}

export default NotFound