import { React, useState, useEffect } from 'react'
import { Form, Radio } from 'antd';
import { currencyFormat, currencyFormat1 } from 'services/currencyFormat'

function BoxRadio({ dataAll, dataInputFull, inputShopTypeNum, checkBtnShowRadio,radioBlank }) {


    // start radio 1
    const radioAll1 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeGroupNum === "1").length
    const radioAll1_1 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "1.1 ไฮเปอร์มาร์เก็ต").length
    const radioAll1_2 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "1.2 ซุปเปอร์มาร์เก็ต").length
    const radioAll1_3 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "1.3 ร้านค้าส่ง").length
    const radioAll1_4 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "1.4 มินิมาร์ท").length
    const radioAll1_5 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "1.5 ร้านโชห่วย (ขนาด 1 คูหา)").length
    const radioAll1_6 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "1.6 ร้านโชห่วย(มากกว่า 1 คูหา)").length
    const radioAll1_7 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "1.7 ร้านค้าส่ง+ปลีก").length
    const radioAll1_8 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "1.8 แผงร้านค้าในตลาดสด").length
    const radioAll1_9 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "1.9 ดิสทริบิวเตอร์").length
    const radioAll1_10 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "1.10 เซลส์ดิสทริบิวเตอร์").length
    const radioAll1_total = dataAll.length === 0 ? 0 : radioAll1_1 + radioAll1_2 + radioAll1_3 + radioAll1_4 + radioAll1_5 + radioAll1_6 + radioAll1_7 + radioAll1_8 + radioAll1_9 + radioAll1_10

    const radioPercent1_1 = `${Number(radioAll1_1)}` / `${Number(radioAll1_total)}` * Number(100)
    const radioPercent1_2 = `${Number(radioAll1_2)}` / `${Number(radioAll1_total)}` * Number(100)
    const radioPercent1_3 = `${Number(radioAll1_3)}` / `${Number(radioAll1_total)}` * Number(100)
    const radioPercent1_4 = `${Number(radioAll1_4)}` / `${Number(radioAll1_total)}` * Number(100)
    const radioPercent1_5 = `${Number(radioAll1_5)}` / `${Number(radioAll1_total)}` * Number(100)
    const radioPercent1_6 = `${Number(radioAll1_6)}` / `${Number(radioAll1_total)}` * Number(100)
    const radioPercent1_7 = `${Number(radioAll1_7)}` / `${Number(radioAll1_total)}` * Number(100)
    const radioPercent1_8 = `${Number(radioAll1_8)}` / `${Number(radioAll1_total)}` * Number(100)
    const radioPercent1_9 = `${Number(radioAll1_9)}` / `${Number(radioAll1_total)}` * Number(100)
    const radioPercent1_10 = `${Number(radioAll1_10)}` / `${Number(radioAll1_total)}` * Number(100)

    // end radio 1

    // start radio 2
    const radioAll2 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeGroupNum === "2").length
    const radioAll2_1 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "2.1 ภัตตาคาร").length
    const radioAll2_2 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "2.2 ร้านอาหารขนาดกลาง").length
    const radioAll2_3 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "2.3 ร้านอาหารขนาดเล็ก").length
    const radioAll2_4 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "2.4 ผับ - บาร์ - เลาจ์").length
    const radioAll2_5 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "2.5 ร้านกาแฟ - เบเกอรี่").length
    const radioAll2_6 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "2.6 ร้านอาหารเพิง").length
    const radioAll2_7 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "2.7 ร้านอาหารรถเข็น").length
    const radioAll2_8 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "2.8 ร้านเครื่องดื่มรถเข็น").length
    const radioAll2_9 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "2.9 ร้านในฟู้ดคอร์ท-โรงอาหาร").length
    const radioAll2_10 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "2.10 แผงอาหารในตลาดสด").length
    const radioAll2_total = dataAll.length === 0 ? 0 : radioAll2_1 + radioAll2_2 + radioAll2_3 + radioAll2_4 + radioAll2_5 + radioAll2_6 + radioAll2_7 + radioAll2_8 + radioAll2_9 + radioAll2_10

    const radioPercent2_1 = `${Number(radioAll2_1)}` / `${Number(radioAll2_total)}` * Number(100)
    const radioPercent2_2 = `${Number(radioAll2_2)}` / `${Number(radioAll2_total)}` * Number(100)
    const radioPercent2_3 = `${Number(radioAll2_3)}` / `${Number(radioAll2_total)}` * Number(100)
    const radioPercent2_4 = `${Number(radioAll2_4)}` / `${Number(radioAll2_total)}` * Number(100)
    const radioPercent2_5 = `${Number(radioAll2_5)}` / `${Number(radioAll2_total)}` * Number(100)
    const radioPercent2_6 = `${Number(radioAll2_6)}` / `${Number(radioAll2_total)}` * Number(100)
    const radioPercent2_7 = `${Number(radioAll2_7)}` / `${Number(radioAll2_total)}` * Number(100)
    const radioPercent2_8 = `${Number(radioAll2_8)}` / `${Number(radioAll2_total)}` * Number(100)
    const radioPercent2_9 = `${Number(radioAll2_9)}` / `${Number(radioAll2_total)}` * Number(100)
    const radioPercent2_10 = `${Number(radioAll2_10)}` / `${Number(radioAll2_total)}` * Number(100)

    // end radio 2

    // start radio 3
    const radioAll3 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeGroupNum === "3").length
    const radioAll3_1 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "3.1 ร้านบิวตี้ซุปเปอร์มาร์เก็ต").length
    const radioAll3_2 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "3.2 ร้านบิวตี้ค้าส่ง").length
    const radioAll3_3 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "3.3 ร้านบิวตี้ขายปลีก").length
    const radioAll3_4 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "3.4 ร้านทำผมผู้หญิง").length
    const radioAll3_5 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "3.5 ร้านตัดผมผู้ชาย").length
    const radioAll3_6 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "3.6 ร้านทำผม ชาย-หญิง").length
    const radioAll3_7 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "3.7 ร้านขายอุปกรณ์เสริมสวย").length
    const radioAll3_total = radioAll3_1 + radioAll3_2 + radioAll3_3 + radioAll3_4 + radioAll3_5 + radioAll3_6 + radioAll3_7

    const radioPercent3_1 = `${Number(radioAll3_1)}` / `${Number(radioAll3_total)}` * Number(100)
    const radioPercent3_2 = `${Number(radioAll3_2)}` / `${Number(radioAll3_total)}` * Number(100)
    const radioPercent3_3 = `${Number(radioAll3_3)}` / `${Number(radioAll3_total)}` * Number(100)
    const radioPercent3_4 = `${Number(radioAll3_4)}` / `${Number(radioAll3_total)}` * Number(100)
    const radioPercent3_5 = `${Number(radioAll3_5)}` / `${Number(radioAll3_total)}` * Number(100)
    const radioPercent3_6 = `${Number(radioAll3_6)}` / `${Number(radioAll3_total)}` * Number(100)
    const radioPercent3_7 = `${Number(radioAll3_7)}` / `${Number(radioAll3_total)}` * Number(100)

    // end radio 3

    // start radio 4
    const radioAll4 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeGroupNum === "4").length
    const radioAll4_1 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "4.1 ร้านขายยาส่ง").length
    const radioAll4_2 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "4.2 ร้านขายยาปลีก").length
    const radioAll4_3 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "4.5 โรงพยาบาล").length
    const radioAll4_total = dataAll.length === 0 ? 0 : radioAll4_1 + radioAll4_2 + radioAll4_3

    const radioPercent4_1 = `${Number(radioAll4_1)}` / `${Number(radioAll4_total)}` * Number(100)
    const radioPercent4_2 = `${Number(radioAll4_2)}` / `${Number(radioAll4_total)}` * Number(100)
    const radioPercent4_3 = `${Number(radioAll4_3)}` / `${Number(radioAll4_total)}` * Number(100)
    // end radio 4

    // start radio 5
    const radioAll5 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeGroupNum === "5").length
    const radioAll5_1 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "5.1 ร้านสินค้าแม่และเด็ก").length
    const radioAll5_total = dataAll.length === 0 ? 0 : radioAll5_1

    const radioPercent5_1 = `${Number(radioAll5_1)}` / `${Number(radioAll5_total)}` * Number(100)
    // end radio 5

    // start radio 6
    const radioAll66aaaa = dataAll.length === 0 ? 0 : dataAll.map(items => items.ShopTypeGroupNum)
    const radioAll66 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeGroupNum === "6").length
    const radioAllZ = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeGroupNum === "Z").length
    const radioAllX = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeGroupNum === "X").length
    const radioAll6 = dataAll.length === 0 ? 0 : Number(radioAll66) + Number(radioAllZ) + Number(radioAllX)


    const radioAll6_1 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "6.1 โรงแรม-รีสอร์ท").length
    const radioAll6_2 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "6.2 ร้านขายของสัตว์เลี้ยง").length
    const radioAll6_3 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "6.4 ร้านซ่อม หรือขายอุปกรณ์รถยนต์").length
    const radioAll6_4 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "6.5 ร้านเครื่องใช้ไฟฟ้า").length
    const radioAll6_5 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "6.6 ร้านอุปกรณ์ก่อสร้าง").length
    const radioAll6_6 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "กรุณาบันทึกข้อมูล").length
    const radioAll6_7 = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeName === "ร้านประเภทอื่นๆ").length
    const radioAll6_total = radioAll6_1 + radioAll6_2 + radioAll6_3 + radioAll6_4 + radioAll6_5 + radioAll6_6 + radioAll6_7
    const radioAll6_total_6 = radioAll6_6 + radioAll6_7


    // const radioAll6_7s = dataAll.length === 0 ? 0 : dataAll.map(items => items.ShopTypeName)

    // console.log(radioAll6_7s);

    const radioPercent6_1 = dataAll.length === 0 ? 0 : `${Number(radioAll6_1)}` / `${Number(radioAll6_total)}` * Number(100)
    const radioPercent6_2 = dataAll.length === 0 ? 0 : `${Number(radioAll6_2)}` / `${Number(radioAll6_total)}` * Number(100)
    const radioPercent6_3 = dataAll.length === 0 ? 0 : `${Number(radioAll6_3)}` / `${Number(radioAll6_total)}` * Number(100)
    const radioPercent6_4 = dataAll.length === 0 ? 0 : `${Number(radioAll6_4)}` / `${Number(radioAll6_total)}` * Number(100)
    const radioPercent6_5 = dataAll.length === 0 ? 0 : `${Number(radioAll6_5)}` / `${Number(radioAll6_total)}` * Number(100)
    const radioPercent6_6 = dataAll.length === 0 ? 0 : `${Number(radioAll6_6)}` / `${Number(radioAll6_total)}` * Number(100)
    const radioPercent6_7 = dataAll.length === 0 ? 0 : `${Number(radioAll6_total_6)}` / `${Number(radioAll6_total)}` * Number(100)


    // end radio 6

    const radioAllT = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopTypeGroupNum).length


    const radioAllNofilter1 = dataInputFull.length === 0 ? 0 : dataInputFull.filter(items => items.ShopTypeGroupNum === "1").length
    const radioAllNofilter2 = dataInputFull.length === 0 ? 0 : dataInputFull.filter(items => items.ShopTypeGroupNum === "2").length
    const radioAllNofilter3 = dataInputFull.length === 0 ? 0 : dataInputFull.filter(items => items.ShopTypeGroupNum === "3").length
    const radioAllNofilter4 = dataInputFull.length === 0 ? 0 : dataInputFull.filter(items => items.ShopTypeGroupNum === "4").length
    const radioAllNofilter5 = dataInputFull.length === 0 ? 0 : dataInputFull.filter(items => items.ShopTypeGroupNum === "5").length
    const radioAllNofilter66 = dataInputFull.length === 0 ? 0 : dataInputFull.filter(items => items.ShopTypeGroupNum === "6").length
    const radioAllNofilterZ = dataInputFull.length === 0 ? 0 : dataInputFull.filter(items => items.ShopTypeGroupNum === "Z").length
    const radioAllNofilterX = dataInputFull.length === 0 ? 0 : dataInputFull.filter(items => items.ShopTypeGroupNum === "X").length
    const radioAllNofilter6 = Number(radioAllNofilter66) + Number(radioAllNofilterZ) + Number(radioAllNofilterX)
    const radioAllNofilterT = dataInputFull.length === 0 ? 0 : dataInputFull.filter(items => items.ShopTypeGroupNum).length


    const percent1 = dataAll.length === 0 ? 0 : `${Number(radioAll1)}` / `${Number(radioAllT)}` * Number(100)
    const percent2 = dataAll.length === 0 ? 0 : `${Number(radioAll2)}` / `${Number(radioAllT)}` * Number(100)
    const percent3 = dataAll.length === 0 ? 0 : `${Number(radioAll3)}` / `${Number(radioAllT)}` * Number(100)
    const percent4 = dataAll.length === 0 ? 0 : `${Number(radioAll4)}` / `${Number(radioAllT)}` * Number(100)
    const percent5 = dataAll.length === 0 ? 0 : `${Number(radioAll5)}` / `${Number(radioAllT)}` * Number(100)
    const percent6 = dataAll.length === 0 ? 0 : `${Number(radioAll6)}` / `${Number(radioAllT)}` * Number(100)


    const [form] = Form.useForm();
    const [currentRadio, setCurrentRadio] = useState((inputShopTypeNum !== '') ? inputShopTypeNum : '');
    const radiotype = (currentRadio !== '') ? currentRadio : inputShopTypeNum


    useEffect(() => { //get data all

        radioBlank === true && setCurrentRadio(inputShopTypeNum)

    }, [inputShopTypeNum,radioBlank]) // eslint-disable-line react-hooks/exhaustive-deps

    const onChangeRadio = (values) => {

        // console.log('values', values);
        setCurrentRadio(values)

    }



    return (
        <div>
            <div className="alert alert-secondary mt-2 mb-0" style={{ backgroundColor: '#E6E6E6' }} role="alert">
                ประเภทธุรกิจ
                <div className="radio-tile-group">
                    <div className="row g-2 mt-2">
                        <div className="col-6 col-md-2">
                            <div className="card text-center">
                                <div className="input-container">
                                    <input id="walk1" className="radio-button" type="radio" name="radio" value="1" onChange={(e) => onChangeRadio(e.target.value)} disabled={(radioAll1 === 0 ) ?  true : false} checked={radiotype === '1' ? true : (radiotype === '1' && inputShopTypeNum === '') ? true : false} />
                                    <div className={`${radioAll1 === 0 ? "radio-tile zero" : "radio-tile"}`}>
                                        <div style={{ display: 'block', width: '100%' }}>
                                            <div className="card-header" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
                                                ร้านสินค้าอุปโภค-บริโภค
                                            </div>
                                            <div className="card-body">
                                                <label className='num-text'>{currencyFormat(radioAll1)}{(radioAll1 !== radioAllNofilter1) && `(${currencyFormat(radioAllNofilter1)})`}</label>
                                            </div>
                                            <div className="card-footer">
                                                <label className='num-text-percent'>({currencyFormat(percent1)}%)</label>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: `${percent1}%` }} aria-valuenow={percent1} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-2">
                            <div className="card text-center">
                                <div className="input-container">
                                    <input id="bike" className="radio-button" type="radio" name="radio" value="2" onChange={(e) => onChangeRadio(e.target.value)} disabled={(radioAll2 === 0 ) ?  true : false} checked={radiotype === '2' ? true : (radiotype === '2' && inputShopTypeNum === '') ? true : false} />
                                    <div className={`${radioAll2 === 0 ? "radio-tile zero" : "radio-tile"}`}>
                                        <div style={{ display: 'block', width: '100%' }}>
                                            <div className="card-header" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
                                                ร้านอาหาร
                                            </div>
                                            <div className="card-body">
                                                <label className='num-text'>{currencyFormat(radioAll2)}{(radioAll2 !== radioAllNofilter2) && `(${currencyFormat(radioAllNofilter2)})`}</label>
                                            </div>
                                            <div className="card-footer">
                                                <label className='num-text-percent'>({currencyFormat(percent2)}%)</label>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: `${percent2}%` }} aria-valuenow={percent2} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-2">
                            <div className="card text-center">
                                <div className="input-container">
                                    <input id="drive" className="radio-button" type="radio" name="radio" value="3" onChange={(e) => onChangeRadio(e.target.value)} disabled={(radioAll3 === 0 ) ?  true : false} checked={radiotype === '3' ? true : (radiotype === '3' && inputShopTypeNum === '') ? true : false} />
                                    <div className={`${radioAll3 === 0 ? "radio-tile zero" : "radio-tile"}`}>
                                        <div style={{ display: 'block', width: '100%' }}>
                                            <div className="card-header" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
                                                ร้านเสริมสวย ความงาม
                                            </div>
                                            <div className="card-body">
                                                <label className='num-text'>{currencyFormat(radioAll3)}{(radioAll3 !== radioAllNofilter3) && `(${currencyFormat(radioAllNofilter3)})`}</label>
                                            </div>
                                            <div className="card-footer">
                                                <label className='num-text-percent'>({currencyFormat(percent3)}%)</label>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: `${percent3}%` }} aria-valuenow={percent3} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-2">
                            <div className="card text-center">
                                <div className="input-container">
                                    <input id="fly" className="radio-button" type="radio" name="radio" value="4" onChange={(e) => onChangeRadio(e.target.value)} disabled={(radioAll4 === 0 ) ?  true : false} checked={radiotype === '4' ? true : (radiotype === '4' && inputShopTypeNum === '') ? true : false} />
                                    <div className={`${radioAll4 === 0 ? "radio-tile zero" : "radio-tile"}`}>
                                        <div style={{ display: 'block', width: '100%' }}>
                                            <div className="card-header" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
                                                ร้านขายยา
                                            </div>
                                            <div className="card-body">
                                                <label className='num-text'>{currencyFormat(radioAll4)}{(radioAll4 !== radioAllNofilter4) && `(${currencyFormat(radioAllNofilter4)})`}</label>
                                            </div>
                                            <div className="card-footer">
                                                <label className='num-text-percent'>({currencyFormat(percent4)}%)</label>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: `${percent4}%` }} aria-valuenow={percent4} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-2">
                            <div className="card text-center">
                                <div className="input-container">
                                    <input id="fly2" className="radio-button" type="radio" name="radio" value="5" onChange={(e) => onChangeRadio(e.target.value)} disabled={(radioAll5 === 0 ) ?  true : false} checked={radiotype === '5' ? true : (radiotype === '5' && inputShopTypeNum === '') ? true : false} />
                                    <div className={`${radioAll5 === 0 ? "radio-tile zero" : "radio-tile"}`}>
                                        <div style={{ display: 'block', width: '100%' }}>
                                            <div className="card-header" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
                                                ร้านแม่และเด็ก
                                            </div>
                                            <div className="card-body">
                                                <label className='num-text'>{currencyFormat(radioAll5)}{(radioAll5 !== radioAllNofilter5) && `(${currencyFormat(radioAllNofilter5)})`}</label>
                                            </div>
                                            <div className="card-footer">
                                                <label className='num-text-percent'>({currencyFormat(percent5)}%)</label>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: `${percent5}%` }} aria-valuenow={percent5} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-md-2">
                            <div className="card text-center">
                                <div className="input-container">
                                    <input id="fly3" className="radio-button" type="radio" name="radio" value="6" onChange={(e) => onChangeRadio(e.target.value)} disabled={(radioAll6 === 0 ) ?  true : false} checked={radiotype === '6' ? true : (radiotype === '6' && inputShopTypeNum === '') ? true : false} />
                                    <div className={`${radioAll6 === 0 ? "radio-tile zero" : "radio-tile"}`}>
                                        <div style={{ display: 'block', width: '100%' }}>
                                            <div className="card-header" style={{whiteSpace: "nowrap",overflow: "hidden",textOverflow: "ellipsis"}}>
                                                ร้านค้าอื่นๆ
                                            </div>
                                            <div className="card-body">
                                                <label className='num-text'>{currencyFormat(radioAll6)}{(radioAll6 !== radioAllNofilter6) && `(${currencyFormat(radioAllNofilter6)})`}</label>
                                            </div>
                                            <div className="card-footer">
                                                <label className='num-text-percent'>({currencyFormat(percent6)}%)</label>
                                            </div>
                                            <div className="progress">
                                                <div className="progress-bar" role="progressbar" style={{ width: `${percent6}%` }} aria-valuenow={percent6} aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {(currentRadio !== '1' && inputShopTypeNum === '') ? '' : (inputShopTypeNum !== '' && currentRadio === '1') ?
                    <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ไฮเปอร์มาร์เก็ต</div>
                                            <div>{currencyFormat(radioAll1_1)} ({currencyFormat(radioPercent1_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_1}%` }} aria-valuenow={radioPercent1_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ซุปเปอร์มาร์เก็ต</div>
                                            <div>{currencyFormat(radioAll1_2)} ({currencyFormat(radioPercent1_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_2}%` }} aria-valuenow={radioPercent1_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านค้าส่ง</div>
                                            <div>{currencyFormat(radioAll1_3)} ({currencyFormat(radioPercent1_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_3}%` }} aria-valuenow={radioPercent1_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_4) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>มินิมาร์ท</div>
                                            <div>{currencyFormat(radioAll1_4)} ({currencyFormat(radioPercent1_4)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_4}%` }} aria-valuenow={radioPercent1_4} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_5) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านโชห่วย (ขนาด 1 คูหา)</div>
                                            <div>{currencyFormat(radioAll1_5)} ({currencyFormat(radioPercent1_5)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_5}%` }} aria-valuenow={radioPercent1_5} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_6) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านโชห่วย(มากกว่า 1 คูหา)</div>
                                            <div>{currencyFormat(radioAll1_6)} ({currencyFormat(radioPercent1_6)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_6}%` }} aria-valuenow={radioPercent1_6} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_7) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านค้าส่ง+ปลีก</div>
                                            <div>{currencyFormat(radioAll1_7)} ({currencyFormat(radioPercent1_7)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_7}%` }} aria-valuenow={radioPercent1_7} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_8) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>แผงร้านค้าในตลาดสด</div>
                                            <div>{currencyFormat(radioAll1_8)} ({currencyFormat(radioPercent1_8)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_8}%` }} aria-valuenow={radioPercent1_8} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_9) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ดิสทริบิวเตอร์</div>
                                            <div>{currencyFormat(radioAll1_9)} ({currencyFormat(radioPercent1_9)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_9}%` }} aria-valuenow={radioPercent1_9} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_10) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>เซลส์ดิสทริบิวเตอร์</div>
                                            <div>{currencyFormat(radioAll1_10)} ({currencyFormat(radioPercent1_10)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_10}%` }} aria-valuenow={radioPercent1_10} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : (checkBtnShowRadio === true && inputShopTypeNum === '1') ?
                    <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ไฮเปอร์มาร์เก็ต</div>
                                            <div>{currencyFormat(radioAll1_1)} ({currencyFormat(radioPercent1_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_1}%` }} aria-valuenow={radioPercent1_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ซุปเปอร์มาร์เก็ต</div>
                                            <div>{currencyFormat(radioAll1_2)} ({currencyFormat(radioPercent1_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_2}%` }} aria-valuenow={radioPercent1_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านค้าส่ง</div>
                                            <div>{currencyFormat(radioAll1_3)} ({currencyFormat(radioPercent1_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_3}%` }} aria-valuenow={radioPercent1_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_4) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>มินิมาร์ท</div>
                                            <div>{currencyFormat(radioAll1_4)} ({currencyFormat(radioPercent1_4)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_4}%` }} aria-valuenow={radioPercent1_4} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_5) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านโชห่วย (ขนาด 1 คูหา)</div>
                                            <div>{currencyFormat(radioAll1_5)} ({currencyFormat(radioPercent1_5)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_5}%` }} aria-valuenow={radioPercent1_5} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_6) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านโชห่วย(มากกว่า 1 คูหา)</div>
                                            <div>{currencyFormat(radioAll1_6)} ({currencyFormat(radioPercent1_6)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_6}%` }} aria-valuenow={radioPercent1_6} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_7) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านค้าส่ง+ปลีก</div>
                                            <div>{currencyFormat(radioAll1_7)} ({currencyFormat(radioPercent1_7)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_7}%` }} aria-valuenow={radioPercent1_7} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_8) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>แผงร้านค้าในตลาดสด</div>
                                            <div>{currencyFormat(radioAll1_8)} ({currencyFormat(radioPercent1_8)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_8}%` }} aria-valuenow={radioPercent1_8} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_9) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ดิสทริบิวเตอร์</div>
                                            <div>{currencyFormat(radioAll1_9)} ({currencyFormat(radioPercent1_9)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_9}%` }} aria-valuenow={radioPercent1_9} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_10) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>เซลส์ดิสทริบิวเตอร์</div>
                                            <div>{currencyFormat(radioAll1_10)} ({currencyFormat(radioPercent1_10)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_10}%` }} aria-valuenow={radioPercent1_10} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        : (radiotype === '1' && inputShopTypeNum === '') ?
                        <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ไฮเปอร์มาร์เก็ต</div>
                                            <div>{currencyFormat(radioAll1_1)} ({currencyFormat(radioPercent1_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_1}%` }} aria-valuenow={radioPercent1_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ซุปเปอร์มาร์เก็ต</div>
                                            <div>{currencyFormat(radioAll1_2)} ({currencyFormat(radioPercent1_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_2}%` }} aria-valuenow={radioPercent1_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านค้าส่ง</div>
                                            <div>{currencyFormat(radioAll1_3)} ({currencyFormat(radioPercent1_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_3}%` }} aria-valuenow={radioPercent1_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_4) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>มินิมาร์ท</div>
                                            <div>{currencyFormat(radioAll1_4)} ({currencyFormat(radioPercent1_4)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_4}%` }} aria-valuenow={radioPercent1_4} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_5) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านโชห่วย (ขนาด 1 คูหา)</div>
                                            <div>{currencyFormat(radioAll1_5)} ({currencyFormat(radioPercent1_5)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_5}%` }} aria-valuenow={radioPercent1_5} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_6) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านโชห่วย(มากกว่า 1 คูหา)</div>
                                            <div>{currencyFormat(radioAll1_6)} ({currencyFormat(radioPercent1_6)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_6}%` }} aria-valuenow={radioPercent1_6} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_7) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านค้าส่ง+ปลีก</div>
                                            <div>{currencyFormat(radioAll1_7)} ({currencyFormat(radioPercent1_7)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_7}%` }} aria-valuenow={radioPercent1_7} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_8) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>แผงร้านค้าในตลาดสด</div>
                                            <div>{currencyFormat(radioAll1_8)} ({currencyFormat(radioPercent1_8)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_8}%` }} aria-valuenow={radioPercent1_8} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_9) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ดิสทริบิวเตอร์</div>
                                            <div>{currencyFormat(radioAll1_9)} ({currencyFormat(radioPercent1_9)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_9}%` }} aria-valuenow={radioPercent1_9} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll1_10) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>เซลส์ดิสทริบิวเตอร์</div>
                                            <div>{currencyFormat(radioAll1_10)} ({currencyFormat(radioPercent1_10)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent1_10}%` }} aria-valuenow={radioPercent1_10} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            : ''
                }
                {(currentRadio !== '2' && inputShopTypeNum === '') ? '' : (inputShopTypeNum !== '' && currentRadio === '2') ?
                    <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ภัตตาคาร</div>
                                            <div>{currencyFormat(radioAll2_1)} ({currencyFormat(radioPercent2_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_1}%` }} aria-valuenow={radioPercent2_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอาหารขนาดกลาง</div>
                                            <div>{currencyFormat(radioAll2_2)} ({currencyFormat(radioPercent2_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_2}%` }} aria-valuenow={radioPercent2_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอาหารขนาดเล็ก</div>
                                            <div>{currencyFormat(radioAll2_3)} ({currencyFormat(radioPercent2_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_3}%` }} aria-valuenow={radioPercent2_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_4) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ผับ - บาร์ - เลาจ์</div>
                                            <div>{currencyFormat(radioAll2_4)} ({currencyFormat(radioPercent2_4)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_4}%` }} aria-valuenow={radioPercent2_4} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_5) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านกาแฟ - เบเกอรี่</div>
                                            <div>{currencyFormat(radioAll2_5)} ({currencyFormat(radioPercent2_5)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_5}%` }} aria-valuenow={radioPercent2_5} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_6) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอาหารเพิง</div>
                                            <div>{currencyFormat(radioAll2_6)} ({currencyFormat(radioPercent2_6)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_6}%` }} aria-valuenow={radioPercent2_6} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_7) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอาหารรถเข็น</div>
                                            <div>{currencyFormat(radioAll2_7)} ({currencyFormat(radioPercent2_7)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_7}%` }} aria-valuenow={radioPercent2_7} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_8) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านเครื่องดื่มรถเข็น</div>
                                            <div>{currencyFormat(radioAll2_8)} ({currencyFormat(radioPercent2_8)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_8}%` }} aria-valuenow={radioPercent2_8} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_9) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านในฟู้ดคอร์ท-โรงอาหาร</div>
                                            <div>{currencyFormat(radioAll2_9)} ({currencyFormat(radioPercent2_9)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_9}%` }} aria-valuenow={radioPercent2_9} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_10) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>แผงอาหารในตลาดสด</div>
                                            <div>{currencyFormat(radioAll2_10)} ({currencyFormat(radioPercent2_10)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_10}%` }} aria-valuenow={radioPercent2_10} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : (checkBtnShowRadio === true && inputShopTypeNum === '2') ?
                    <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ภัตตาคาร</div>
                                            <div>{currencyFormat(radioAll2_1)} ({currencyFormat(radioPercent2_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_1}%` }} aria-valuenow={radioPercent2_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอาหารขนาดกลาง</div>
                                            <div>{currencyFormat(radioAll2_2)} ({currencyFormat(radioPercent2_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_2}%` }} aria-valuenow={radioPercent2_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอาหารขนาดเล็ก</div>
                                            <div>{currencyFormat(radioAll2_3)} ({currencyFormat(radioPercent2_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_3}%` }} aria-valuenow={radioPercent2_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_4) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ผับ - บาร์ - เลาจ์</div>
                                            <div>{currencyFormat(radioAll2_4)} ({currencyFormat(radioPercent2_4)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_4}%` }} aria-valuenow={radioPercent2_4} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_5) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านกาแฟ - เบเกอรี่</div>
                                            <div>{currencyFormat(radioAll2_5)} ({currencyFormat(radioPercent2_5)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_5}%` }} aria-valuenow={radioPercent2_5} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_6) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอาหารเพิง</div>
                                            <div>{currencyFormat(radioAll2_6)} ({currencyFormat(radioPercent2_6)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_6}%` }} aria-valuenow={radioPercent2_6} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_7) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอาหารรถเข็น</div>
                                            <div>{currencyFormat(radioAll2_7)} ({currencyFormat(radioPercent2_7)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_7}%` }} aria-valuenow={radioPercent2_7} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_8) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านเครื่องดื่มรถเข็น</div>
                                            <div>{currencyFormat(radioAll2_8)} ({currencyFormat(radioPercent2_8)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_8}%` }} aria-valuenow={radioPercent2_8} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_9) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านในฟู้ดคอร์ท-โรงอาหาร</div>
                                            <div>{currencyFormat(radioAll2_9)} ({currencyFormat(radioPercent2_9)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_9}%` }} aria-valuenow={radioPercent2_9} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_10) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>แผงอาหารในตลาดสด</div>
                                            <div>{currencyFormat(radioAll2_10)} ({currencyFormat(radioPercent2_10)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_10}%` }} aria-valuenow={radioPercent2_10} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        : (radiotype === '2' && inputShopTypeNum === '') ?
                        <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ภัตตาคาร</div>
                                            <div>{currencyFormat(radioAll2_1)} ({currencyFormat(radioPercent2_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_1}%` }} aria-valuenow={radioPercent2_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอาหารขนาดกลาง</div>
                                            <div>{currencyFormat(radioAll2_2)} ({currencyFormat(radioPercent2_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_2}%` }} aria-valuenow={radioPercent2_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอาหารขนาดเล็ก</div>
                                            <div>{currencyFormat(radioAll2_3)} ({currencyFormat(radioPercent2_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_3}%` }} aria-valuenow={radioPercent2_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_4) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ผับ - บาร์ - เลาจ์</div>
                                            <div>{currencyFormat(radioAll2_4)} ({currencyFormat(radioPercent2_4)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_4}%` }} aria-valuenow={radioPercent2_4} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_5) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านกาแฟ - เบเกอรี่</div>
                                            <div>{currencyFormat(radioAll2_5)} ({currencyFormat(radioPercent2_5)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_5}%` }} aria-valuenow={radioPercent2_5} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_6) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอาหารเพิง</div>
                                            <div>{currencyFormat(radioAll2_6)} ({currencyFormat(radioPercent2_6)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_6}%` }} aria-valuenow={radioPercent2_6} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_7) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอาหารรถเข็น</div>
                                            <div>{currencyFormat(radioAll2_7)} ({currencyFormat(radioPercent2_7)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_7}%` }} aria-valuenow={radioPercent2_7} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_8) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านเครื่องดื่มรถเข็น</div>
                                            <div>{currencyFormat(radioAll2_8)} ({currencyFormat(radioPercent2_8)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_8}%` }} aria-valuenow={radioPercent2_8} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_9) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านในฟู้ดคอร์ท-โรงอาหาร</div>
                                            <div>{currencyFormat(radioAll2_9)} ({currencyFormat(radioPercent2_9)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_9}%` }} aria-valuenow={radioPercent2_9} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll2_10) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>แผงอาหารในตลาดสด</div>
                                            <div>{currencyFormat(radioAll2_10)} ({currencyFormat(radioPercent2_10)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent2_10}%` }} aria-valuenow={radioPercent2_10} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            : ''
                }
                {(currentRadio !== '3' && inputShopTypeNum === '') ? '' : (inputShopTypeNum !== '' && currentRadio === '3') ?
                    <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านบิวตี้ซุปเปอร์มาร์เก็ต</div>
                                            <div>{currencyFormat(radioAll3_1)} ({currencyFormat(radioPercent3_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_1}%` }} aria-valuenow={radioPercent3_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านบิวตี้ค้าส่ง</div>
                                            <div>{currencyFormat(radioAll3_2)} ({currencyFormat(radioPercent3_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_2}%` }} aria-valuenow={radioPercent3_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านบิวตี้ขายปลีก</div>
                                            <div>{currencyFormat(radioAll3_3)} ({currencyFormat(radioPercent3_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_3}%` }} aria-valuenow={radioPercent3_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_4) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านทำผมผู้หญิง</div>
                                            <div>{currencyFormat(radioAll3_4)} ({currencyFormat(radioPercent3_4)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_4}%` }} aria-valuenow={radioPercent3_4} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_5) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านตัดผมผู้ชาย</div>
                                            <div>{currencyFormat(radioAll3_5)} ({currencyFormat(radioPercent3_5)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_5}%` }} aria-valuenow={radioPercent3_5} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_6) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านทำผม ชาย-หญิง</div>
                                            <div>{currencyFormat(radioAll3_6)} ({currencyFormat(radioPercent3_6)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_6}%` }} aria-valuenow={radioPercent3_6} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_7) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านขายอุปกรณ์เสริมสวย</div>
                                            <div>{currencyFormat(radioAll3_7)} ({currencyFormat(radioPercent3_7)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_7}%` }} aria-valuenow={radioPercent3_7} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : (checkBtnShowRadio === true && inputShopTypeNum === '3') ?
                    <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านบิวตี้ซุปเปอร์มาร์เก็ต</div>
                                            <div>{currencyFormat(radioAll3_1)} ({currencyFormat(radioPercent3_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_1}%` }} aria-valuenow={radioPercent3_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านบิวตี้ค้าส่ง</div>
                                            <div>{currencyFormat(radioAll3_2)} ({currencyFormat(radioPercent3_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_2}%` }} aria-valuenow={radioPercent3_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านบิวตี้ขายปลีก</div>
                                            <div>{currencyFormat(radioAll3_3)} ({currencyFormat(radioPercent3_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_3}%` }} aria-valuenow={radioPercent3_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_4) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านทำผมผู้หญิง</div>
                                            <div>{currencyFormat(radioAll3_4)} ({currencyFormat(radioPercent3_4)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_4}%` }} aria-valuenow={radioPercent3_4} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_5) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านตัดผมผู้ชาย</div>
                                            <div>{currencyFormat(radioAll3_5)} ({currencyFormat(radioPercent3_5)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_5}%` }} aria-valuenow={radioPercent3_5} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_6) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านทำผม ชาย-หญิง</div>
                                            <div>{currencyFormat(radioAll3_6)} ({currencyFormat(radioPercent3_6)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_6}%` }} aria-valuenow={radioPercent3_6} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_7) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านขายอุปกรณ์เสริมสวย</div>
                                            <div>{currencyFormat(radioAll3_7)} ({currencyFormat(radioPercent3_7)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_7}%` }} aria-valuenow={radioPercent3_7} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        : (radiotype === '3' && inputShopTypeNum === '') ?
                        <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านบิวตี้ซุปเปอร์มาร์เก็ต</div>
                                            <div>{currencyFormat(radioAll3_1)} ({currencyFormat(radioPercent3_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_1}%` }} aria-valuenow={radioPercent3_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านบิวตี้ค้าส่ง</div>
                                            <div>{currencyFormat(radioAll3_2)} ({currencyFormat(radioPercent3_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_2}%` }} aria-valuenow={radioPercent3_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านบิวตี้ขายปลีก</div>
                                            <div>{currencyFormat(radioAll3_3)} ({currencyFormat(radioPercent3_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_3}%` }} aria-valuenow={radioPercent3_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_4) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านทำผมผู้หญิง</div>
                                            <div>{currencyFormat(radioAll3_4)} ({currencyFormat(radioPercent3_4)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_4}%` }} aria-valuenow={radioPercent3_4} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_5) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านตัดผมผู้ชาย</div>
                                            <div>{currencyFormat(radioAll3_5)} ({currencyFormat(radioPercent3_5)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_5}%` }} aria-valuenow={radioPercent3_5} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_6) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านทำผม ชาย-หญิง</div>
                                            <div>{currencyFormat(radioAll3_6)} ({currencyFormat(radioPercent3_6)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_6}%` }} aria-valuenow={radioPercent3_6} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll3_7) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านขายอุปกรณ์เสริมสวย</div>
                                            <div>{currencyFormat(radioAll3_7)} ({currencyFormat(radioPercent3_7)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent3_7}%` }} aria-valuenow={radioPercent3_7} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            : ''
                }
                {(currentRadio !== '4' && inputShopTypeNum === '') ? '' : (inputShopTypeNum !== '' && currentRadio === '4') ?
                    <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll4_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านขายยาส่ง</div>
                                            <div>{currencyFormat(radioAll4_1)} ({currencyFormat(radioPercent4_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent4_1}%` }} aria-valuenow={radioPercent4_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll4_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านขายยาปลีก</div>
                                            <div>{currencyFormat(radioAll4_2)} ({currencyFormat(radioPercent4_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent4_2}%` }} aria-valuenow={radioPercent4_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll4_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>โรงพยาบาล</div>
                                            <div>{currencyFormat(radioAll4_3)} ({currencyFormat(radioPercent4_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent4_3}%` }} aria-valuenow={radioPercent4_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : (checkBtnShowRadio === true && inputShopTypeNum === '4') ?
                    <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll4_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านขายยาส่ง</div>
                                            <div>{currencyFormat(radioAll4_1)} ({currencyFormat(radioPercent4_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent4_1}%` }} aria-valuenow={radioPercent4_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll4_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านขายยาปลีก</div>
                                            <div>{currencyFormat(radioAll4_2)} ({currencyFormat(radioPercent4_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent4_2}%` }} aria-valuenow={radioPercent4_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll4_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>โรงพยาบาล</div>
                                            <div>{currencyFormat(radioAll4_3)} ({currencyFormat(radioPercent4_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent4_3}%` }} aria-valuenow={radioPercent4_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        : (radiotype === '4' && inputShopTypeNum === '') ?
                        <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll4_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านขายยาส่ง</div>
                                            <div>{currencyFormat(radioAll4_1)} ({currencyFormat(radioPercent4_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent4_1}%` }} aria-valuenow={radioPercent4_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll4_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านขายยาปลีก</div>
                                            <div>{currencyFormat(radioAll4_2)} ({currencyFormat(radioPercent4_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent4_2}%` }} aria-valuenow={radioPercent4_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll4_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>โรงพยาบาล</div>
                                            <div>{currencyFormat(radioAll4_3)} ({currencyFormat(radioPercent4_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent4_3}%` }} aria-valuenow={radioPercent4_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            : ''
                }
                {(currentRadio !== '5' && inputShopTypeNum === '') ? '' : (inputShopTypeNum !== '' && currentRadio === '5') ?
                    <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll5_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านสินค้าแม่และเด็ก</div>
                                            <div>{currencyFormat(radioAll5_1)} ({currencyFormat(radioPercent5_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent5_1}%` }} aria-valuenow={radioPercent5_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : (checkBtnShowRadio === true && inputShopTypeNum === '5') ?
                    <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll5_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านสินค้าแม่และเด็ก</div>
                                            <div>{currencyFormat(radioAll5_1)} ({currencyFormat(radioPercent5_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent5_1}%` }} aria-valuenow={radioPercent5_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        : (radiotype === '5' && inputShopTypeNum === '') ?
                        <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll5_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านสินค้าแม่และเด็ก</div>
                                            <div>{currencyFormat(radioAll5_1)} ({currencyFormat(radioPercent5_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent5_1}%` }} aria-valuenow={radioPercent5_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            : ''
                }
                {(currentRadio !== '6' && inputShopTypeNum === '') ? '' : (inputShopTypeNum !== '' && currentRadio === '6') ?
                    <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>โรงแรม-รีสอร์ท</div>
                                            <div>{currencyFormat(radioAll6_1)} ({currencyFormat(radioPercent6_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_1}%` }} aria-valuenow={radioPercent6_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านขายของสัตว์เลี้ยง</div>
                                            <div>{currencyFormat(radioAll6_2)} ({currencyFormat(radioPercent6_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_2}%` }} aria-valuenow={radioPercent6_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านซ่อม หรือขายอุปกรณ์รถยนต์</div>
                                            <div>{currencyFormat(radioAll6_3)} ({currencyFormat(radioPercent6_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_3}%` }} aria-valuenow={radioPercent6_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_4) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านเครื่องใช้ไฟฟ้า</div>
                                            <div>{currencyFormat(radioAll6_4)} ({currencyFormat(radioPercent6_4)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_4}%` }} aria-valuenow={radioPercent6_4} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_5) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอุปกรณ์ก่อสร้าง</div>
                                            <div>{currencyFormat(radioAll6_5)} ({currencyFormat(radioPercent6_5)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_5}%` }} aria-valuenow={radioPercent6_5} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_total_6) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านประเภทอื่นๆ</div>
                                            <div>{currencyFormat(radioAll6_total_6)} ({currencyFormat(radioPercent6_7)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_7}%` }} aria-valuenow={radioPercent6_7} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    : (checkBtnShowRadio === true && inputShopTypeNum === '6') ?
                    <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>โรงแรม-รีสอร์ท</div>
                                            <div>{currencyFormat(radioAll6_1)} ({currencyFormat(radioPercent6_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_1}%` }} aria-valuenow={radioPercent6_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านขายของสัตว์เลี้ยง</div>
                                            <div>{currencyFormat(radioAll6_2)} ({currencyFormat(radioPercent6_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_2}%` }} aria-valuenow={radioPercent6_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านซ่อม หรือขายอุปกรณ์รถยนต์</div>
                                            <div>{currencyFormat(radioAll6_3)} ({currencyFormat(radioPercent6_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_3}%` }} aria-valuenow={radioPercent6_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_4) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านเครื่องใช้ไฟฟ้า</div>
                                            <div>{currencyFormat(radioAll6_4)} ({currencyFormat(radioPercent6_4)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_4}%` }} aria-valuenow={radioPercent6_4} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_5) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอุปกรณ์ก่อสร้าง</div>
                                            <div>{currencyFormat(radioAll6_5)} ({currencyFormat(radioPercent6_5)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_5}%` }} aria-valuenow={radioPercent6_5} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_total_6) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านประเภทอื่นๆ</div>
                                            <div>{currencyFormat(radioAll6_total_6)} ({currencyFormat(radioPercent6_7)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_7}%` }} aria-valuenow={radioPercent6_7} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                        : (radiotype === '6' && inputShopTypeNum === '') ?
                        <div className="alert alert-secondary mt-2 mb-0" role="alert" style={{ backgroundColor: '#E6E6E6' }}>
                        <div className="row g-2 mt-2">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_1) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>โรงแรม-รีสอร์ท</div>
                                            <div>{currencyFormat(radioAll6_1)} ({currencyFormat(radioPercent6_1)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_1}%` }} aria-valuenow={radioPercent6_1} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_2) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านขายของสัตว์เลี้ยง</div>
                                            <div>{currencyFormat(radioAll6_2)} ({currencyFormat(radioPercent6_2)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_2}%` }} aria-valuenow={radioPercent6_2} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_3) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านซ่อม หรือขายอุปกรณ์รถยนต์</div>
                                            <div>{currencyFormat(radioAll6_3)} ({currencyFormat(radioPercent6_3)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_3}%` }} aria-valuenow={radioPercent6_3} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_4) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านเครื่องใช้ไฟฟ้า</div>
                                            <div>{currencyFormat(radioAll6_4)} ({currencyFormat(radioPercent6_4)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_4}%` }} aria-valuenow={radioPercent6_4} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_5) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านอุปกรณ์ก่อสร้าง</div>
                                            <div>{currencyFormat(radioAll6_5)} ({currencyFormat(radioPercent6_5)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_5}%` }} aria-valuenow={radioPercent6_5} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-4 mb-3">
                                        <div className={(radioAll6_total_6) === 0 ? "alert alert-secondary d-flex justify-content-between mb-0" : "alert alert-primary d-flex justify-content-between mb-0"} role="alert">
                                            <div>ร้านประเภทอื่นๆ</div>
                                            <div>{currencyFormat(radioAll6_total_6)} ({currencyFormat(radioPercent6_7)}%)</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{ width: `${radioPercent6_7}%` }} aria-valuenow={radioPercent6_7} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                            : ''
                }
            </div>
        </div>
    )
}

export default BoxRadio