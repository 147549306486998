import { React, useState, useEffect } from 'react'
import {currencyFormat, currencyFormat1} from 'services/currencyFormat'
import Chart from "react-apexcharts";

function BoxSale({dataAll}) {


    const shopAll = dataAll.length === 0 ? '' : dataAll.length
    const shopShopY = dataAll.length === 0 ? '' : dataAll.filter(e => e.OrdNum !== null).length
    const shopShopYmore = dataAll.length === 0 ? '' : dataAll.filter(e => e.OrdNum > "1").length
    const shopShopYNo = dataAll.length === 0 ? '' : dataAll.filter(e => e.OrdNum === null).length
    const followcount = dataAll.length === 0 ? '' : dataAll.filter(e => e.countfollow).length


    const graphChart = {

        series: [{
            name: 'Inflation',
            data: [shopAll,shopShopY,shopShopYmore,shopShopYNo,followcount],

        }],
        options: {
            chart: {
                height: 350,
                type: 'bar',
                toolbar: {
                    show: false,
                }
            },
            plotOptions: {
                bar: {
                    distributed: true,
                    borderRadius: 6,

                    dataLabels: {
                        position: 'top', // top, center, bottom
                    },
                }
            },
            fill: {
                type: ['solid'],
                colors: ['#198754', '#0D6EFD', '#6C757D', '#DC3545', '#FFC107']
            },
            legend: {
                show: false,
            },

            dataLabels: {
                enabled: true,
                formatter: function (val) {
                    return currencyFormat(val);
                },
                offsetY: -20,
                style: {
                    colors: [],
                    fontSize: '10px',
                    fontFamily: 'Prompt, sans-serif',
                },
            },

            xaxis: {
                categories: ["Acquire", "Active", "Repeat", "Dormant", "Refferal"],
                position: 'bottom',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: true,
                    rotate: 0,
                    style: {
                        colors: [],
                        fontSize: '12px',
                        fontFamily: 'Prompt, sans-serif',
                        fontWeight: 600,
                        cssClass: 'apexcharts-xaxis-label',
                    },
                },
                tooltip: {
                    enabled: false,
                }
            },
            yaxis: {
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: true,
                },
                labels: {
                    show: true,
                    formatter: function (val) {
                        return currencyFormat(val);
                    },
                    style: {
                        colors: [],
                        fontSize: '10px',
                        fontFamily: 'Prompt, sans-serif',
                    },
                }
            },
        },
    };




    return (
        <>
            <div className="card">
                <div className="card-header">ธุรกรรมร้านค้า</div>
                <div className="card-body">
                    <div className='d-flex flex-column'>
                        <div id="chart" className='box-sale-chart'>
                                {
                                dataAll.length === 0 ? '' : <Chart options={graphChart.options} series={graphChart.series} type="bar" height={350} />
                                }

                            </div>
                        <div>
                        <div className="card text-white bg-success mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">จำนวนร้านค้าทั้งหมด</h5>
                                    <div className='d-flex justify-content-between'>
                                        <span className="card-text">{currencyFormat(shopAll)}</span>
                                        <span className="card-text">Acquire</span>
                                    </div>
                                </div>
                            </div>
                            <div className="card text-white bg-primary mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">จำนวนร้านค้าทั้งหมดที่มีการทำธุรกรรม</h5>
                                    <div className='d-flex justify-content-between'>
                                        <span className="card-text">{currencyFormat(shopShopY)}</span>
                                        <span className="card-text">Active</span>
                                    </div>
                                </div>
                            </div>
                            <div className="card text-white bg-secondary mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">จำนวนร้านค้าเฉพาะที่มีการทำธุรกรรมมากกว่า 1 ครั้ง</h5>
                                    <div className='d-flex justify-content-between'>
                                        <span className="card-text">{currencyFormat(shopShopYmore)}</span>
                                        <span className="card-text">Repeat</span>
                                    </div>
                                </div>
                            </div>
                            <div className="card text-white bg-danger  mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">จำนวนร้านค้าที่ไม่เคยมีธุรกรรม</h5>
                                    <div className='d-flex justify-content-between'>
                                        <span className="card-text">{currencyFormat(shopShopYNo)}</span>
                                        <span className="card-text">Dormant</span>
                                    </div>
                                </div>
                            </div>
                            <div className="card text-dark bg-warning mb-3">
                                <div className="card-body">
                                    <h5 className="card-title">จำนวนร้านค้าที่มีลูกค้าติดตาม (Line 2C)</h5>
                                    <div className='d-flex justify-content-between'>
                                        <span className="card-text">{currencyFormat(followcount)}</span>
                                        <span className="card-text">Refferal</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BoxSale