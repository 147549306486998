const currencyFormat = (number) => {
    return Intl.NumberFormat('th-TH', {
        // style: 'currency',
        currency: 'THB',
        maximumFractionDigits: 2,
    }).format(number)
}


const currencyFormat1 = (number) => {
    return Intl.NumberFormat('th-TH', {
        // style: 'currency',
        currency: 'THB',
        maximumFractionDigits: 1,
    }).format(number)
}


module.exports = {
    currencyFormat,
    currencyFormat1
};