import { React, useState, useEffect } from 'react'
import { Link } from "react-router-dom";
import { currencyFormat, currencyFormat1 } from 'services/currencyFormat'
import { Button, Table, Tabs } from 'antd'
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { GoogleMap, LoadScript, Marker, InfoWindowF } from '@react-google-maps/api';

function BoxMap({ dataAll }) {

    function getUniqueListBy(arr, key) {
        return [...new Map(arr.map(item => [item[key], item])).values()]
    }

    const [value, setValue] = useState(0);
    const [valueY, setValueY] = useState(0);
    const [valueF, setValueF] = useState(0);



    const [selectedPark, setSelectedPark] = useState(null);
    const [pinMap, setPinMap] = useState('') // Number of choose radio type
    const [currentPage, setCurrentPage] = useState(0)


    const resultNoSaleShopY = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === null)
    const resultNoSaleShopYSort = dataAll.length === 0 ? 0 :  resultNoSaleShopY.sort((a, b) => (a.ShopID > b.ShopID) ? 1 : -1)




    const saleShopY0 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === null)
    const saleShopY1 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y1")
    const saleShopY2 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y2")
    const saleShopY3 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y3")
    const saleShopY4 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y4")
    const saleShopY5 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y5")
    const saleShopY6 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y6")
    const saleShopY7 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y7")
    const saleShopY8 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y8")
    const saleShopY9 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y9")
    const saleShopY10 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y10")
    const saleShopY11 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y11")
    const saleShopY12 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y12")
    const saleShopY13 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup === "Y13")
    const saleShopYYY = dataAll.length === 0 ? 0 : dataAll.filter(e => e.PurGroup !== null && e.PurGroup !== "Y1")
    const saleShopYYYMap = dataAll.length === 0 ? 0 : saleShopYYY.map(item => item.PurGroup)
    const sortShop = dataAll.length === 0 ? 0 : saleShopYYYMap.map(x => x.replace("Y", "")).sort((a, b) => a - b);

    const transactionLength = [
        saleShopY0.length === 0 ? 100 : 1,
        saleShopY1.length === 0 ? 100 : 2,
        saleShopY2.length === 0 ? 100 : 3,
        saleShopY3.length === 0 ? 100 : 4,
        saleShopY4.length === 0 ? 100 : 5,
        saleShopY5.length === 0 ? 100 : 6,
        saleShopY6.length === 0 ? 100 : 7,
        saleShopY7.length === 0 ? 100 : 8,
        saleShopY8.length === 0 ? 100 : 9,
        saleShopY9.length === 0 ? 100 : 10,
        saleShopY10.length === 0 ? 100 : 11,
        saleShopY11.length === 0 ? 100 : 12,
        saleShopY12.length === 0 ? 100 : 13,
        saleShopY13.length  === 0 ? 100 : 14
        
    ];

    const minArray = Math.min(...transactionLength)



useEffect(() => {  // BTN resetForm

    setValueY(minArray)

  }, [dataAll]) // eslint-disable-line react-hooks/exhaustive-deps



    const follow10 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.countfollow >= 1 && e.countfollow <= 10)
    const follow50 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.countfollow >= 11 && e.countfollow <= 50)
    const follow100 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.countfollow >= 51 && e.countfollow <= 100)
    const follow150 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.countfollow >= 101 && e.countfollow <= 150)
    const follow151 = dataAll.length === 0 ? 0 : dataAll.filter(e => e.countfollow >= 151)



    const firstid = dataAll.length === 0 ? 0 : resultNoSaleShopY.map(items => items.ShopID)
    const firstids = dataAll.length === 0 ? 0 : firstid[0]
    const dataShop = dataAll.length === 0 ? 0 : dataAll.filter(items => items.ShopID === ((pinMap === '') ? firstids : pinMap))
    const numLatitude = dataAll.length === 0 ? 0 : dataShop.map(items => items.Latitude)
    const numLongitude = dataAll.length === 0 ? 0 : dataShop.map(items => items.Longitude)

    const center = {
        lat: (numLatitude[0] == null) ? 15.861994672946953 : numLatitude[0],
        lng: (numLongitude[0] == null) ? 100.61794432855675 : numLongitude[0]
    };
    const containerStyle = {
        height: "100vh",
    };
    const options = {
        scrollwheel: false,
    }


    const setValueYY = (e) => { // Reset page Table เมื่อกดเปลี่ยนหน้า
        setValueY(e)
        setCurrentPage(1)
    }

    const onhandleTabsShop = () => {
        // setValues(0);
        setValue(0);
        setValueY(minArray)
    }
    const onhandleTabsDist = () => {
        // setValues(1);
        setValue(1);
    }
    const onhandleTabsShopFollow = () => {
        // setValues(2);
        setValue(2);
        setValueF(0);

    }

    const [animationPoint, setAnimationPoint] = useState(1)
    const setTimePin = (e) => { // Animation Pin

        setPinMap(e)
        setAnimationPoint(2)

        setTimeout(() => {
            setAnimationPoint(0) // ไม่เด้ง
            // setAnimationPoint(1) // เด้งๆ
        }, 550);


    }
    const handleChange = (pagination, filters, sorter) => {
        setCurrentPage(pagination.current)
    };

    function TabPanel(props) {

        const { children, value, index, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`full-width-tabpanel-${index}`}
                aria-labelledby={`full-width-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Box p={3}>
                        {children}
                        {/* <Typography component={'div'}>{children}</Typography> */}
                    </Box>
                )
                }
            </div>
        );
    }

    const columnsShop = [ //Table y0
        {

            title: 'ที่ตั้ง', //Tablae Y0
            dataIndex: 'pinshopid',
            ellipsis: true,
            render: (text, record) => (

                (record.pinLongitude === null) ? <img src='./images/no-point.png' width="25px" alt=""  style={{opacity:'0.3'}}/> :
                    <div>
                        {/* <a type="button" href="#transaction" onClick={() => setTimePin(record.shopid)}>{record.shopid === pinMap ? <img src='./images/focus.png' width="20%" alt={record.dist} /> : <img src={photoPoint(record.dist)} width="50%" alt={record.dist} />}</a> */}
                        {/* <a type="button" href="#transaction" onClick={() => setTimePin(record.shopid)}>{record.shopid === firstids && pinMap === '' ? <img src='./images/point-8.png' width="25px" alt={record.dist} /> : record.shopid === pinMap ? <img src='./images/point-8.png' width="50%" alt={record.dist} /> : ''}</a> */}
                        <a type="button" href="#transaction" onClick={() => setTimePin(record.shopid)}>{record.shopid === firstids && pinMap === '' ? <img src='./images/point-8.png' width="25px" alt={record.dist} /> : <img src='./images/point-8.png' width="25px" alt={record.dist} />}</a>
                    </div>
            ),
            width: 80,
        },
        {

            title: 'รหัสร้าน', //Tablae Y0
            dataIndex: 'shopid',
            ellipsis: true,
            render: (text, record) => (

                // <Link to={`/profile?search=${record.shopid}`} target="_blank">{record.shopid}</Link>
                <a type="button" href="#transaction" onClick={() => setTimePin(record.shopid)}>{record.shopid}</a>
            ),

        },
        {

            title: 'ชื่อร้านค้า',
            dataIndex: 'shopname',
            ellipsis: true,
            // key: 'shopname'

            render: (text, record) => (

                <Link to={`/profile?search=${record.shopid}`} target="_blank">{record.shopname}</Link>
                // <Link to={`/profile?id=${record.shopid}`} target="_blank">{record.shopname}</Link>

            ),
        },

    ];


    const dataShops = []; // Data Y0
    const resultNoSaleShopYas = dataAll.length === 0 ? 0 : resultNoSaleShopY.map((eve, index) => {
        let obj3 = {
            key: index,
            dist: eve.dist_name,
            pinLatitude: `${eve.Latitude},${eve.Longitude}`,
            pinLongitude: eve.Longitude,
            shopid: eve.ShopID,
            shopname: eve.ShopName,
        };

        return dataShops.push(obj3);

    });

    const dataShopsY1 = []; // Data Y1
    const saleShopY1s = dataAll.length === 0 ? 0 : saleShopY1.map((ey1, index) => {
        let Y1 = {
            key: index,
            dist: ey1.dist_name,
            pinLatitude: `${ey1.Latitude},${ey1.Longitude}`,
            pinLongitude: ey1.Longitude,
            shopid: ey1.ShopID,
            shopname: ey1.ShopName,
        };
        return dataShopsY1.push(Y1);
    });

    const dataShopsY2 = []; // Data Y2
    const saleShopY2s = dataAll.length === 0 ? 0 : saleShopY2.map((ey2, index) => {
        let Y2 = {
            key: index,
            dist: ey2.dist_name,
            pinLatitude: `${ey2.Latitude},${ey2.Longitude}`,
            pinLongitude: ey2.Longitude,
            shopid: ey2.ShopID,
            shopname: ey2.ShopName,
        };
        return dataShopsY2.push(Y2);
    });

    const dataShopsY3 = []; // Data Y3
    const saleShopY3s = dataAll.length === 0 ? 0 : saleShopY3.map((ey3, index) => {
        let Y3 = {
            key: index,
            dist: ey3.dist_name,
            pinLatitude: `${ey3.Latitude},${ey3.Longitude}`,
            pinLongitude: ey3.Longitude,
            shopid: ey3.ShopID,
            shopname: ey3.ShopName,
        };
        return dataShopsY3.push(Y3);
    });

    const dataShopsY4 = []; // Data Y4
    const saleShopY4s = dataAll.length === 0 ? 0 : saleShopY4.map((ey4, index) => {
        let Y4 = {
            key: index,
            dist: ey4.dist_name,
            pinLatitude: `${ey4.Latitude},${ey4.Longitude}`,
            pinLongitude: ey4.Longitude,
            shopid: ey4.ShopID,
            shopname: ey4.ShopName,
        };
        return dataShopsY4.push(Y4);
    });

    const dataShopsY5 = []; // Data Y5
    const saleShopY5s = dataAll.length === 0 ? 0 : saleShopY5.map((ey5, index) => {
        let Y5 = {
            key: index,
            dist: ey5.dist_name,
            pinLatitude: `${ey5.Latitude},${ey5.Longitude}`,
            pinLongitude: ey5.Longitude,
            shopid: ey5.ShopID,
            shopname: ey5.ShopName,
        };
        return dataShopsY5.push(Y5);
    });

    const dataShopsY6 = []; // Data Y6
    const saleShopY6s = dataAll.length === 0 ? 0 : saleShopY6.map((ey6, index) => {
        let Y6 = {
            key: index,
            dist: ey6.dist_name,
            pinLatitude: `${ey6.Latitude},${ey6.Longitude}`,
            pinLongitude: ey6.Longitude,
            shopid: ey6.ShopID,
            shopname: ey6.ShopName,
        };
        return dataShopsY6.push(Y6);
    });

    const dataShopsY7 = []; // Data Y7
    const saleShopY7s = dataAll.length === 0 ? 0 : saleShopY7.map((ey7, index) => {
        let Y7 = {
            key: index,
            dist: ey7.dist_name,
            pinLatitude: `${ey7.Latitude},${ey7.Longitude}`,
            pinLongitude: ey7.Longitude,
            shopid: ey7.ShopID,
            shopname: ey7.ShopName,
        };
        return dataShopsY7.push(Y7);
    });

    const dataShopsY8 = []; // Data Y8
    const saleShopY8s = dataAll.length === 0 ? 0 : saleShopY8.map((ey8, index) => {
        let Y8 = {
            key: index,
            dist: ey8.dist_name,
            pinLatitude: `${ey8.Latitude},${ey8.Longitude}`,
            pinLongitude: ey8.Longitude,
            shopid: ey8.ShopID,
            shopname: ey8.ShopName,
        };
        return dataShopsY8.push(Y8);
    });

    const dataShopsY9 = []; // Data Y9
    const saleShopY9s = dataAll.length === 0 ? 0 : saleShopY9.map((ey9, index) => {
        let Y9 = {
            key: index,
            dist: ey9.dist_name,
            pinLatitude: `${ey9.Latitude},${ey9.Longitude}`,
            pinLongitude: ey9.Longitude,
            shopid: ey9.ShopID,
            shopname: ey9.ShopName,
        };
        return dataShopsY9.push(Y9);
    });

    const dataShopsY10 = []; // Data Y10
    const saleShopY10s = dataAll.length === 0 ? 0 : saleShopY10.map((ey10, index) => {
        let Y10 = {
            key: index,
            dist: ey10.dist_name,
            pinLatitude: `${ey10.Latitude},${ey10.Longitude}`,
            pinLongitude: ey10.Longitude,
            shopid: ey10.ShopID,
            shopname: ey10.ShopName,
        };
        return dataShopsY10.push(Y10);
    });

    const dataShopsY11 = []; // Data Y11
    const saleShopY11s = dataAll.length === 0 ? 0 : saleShopY11.map((ey11, index) => {
        let Y11 = {
            key: index,
            dist: ey11.dist_name,
            pinLatitude: `${ey11.Latitude},${ey11.Longitude}`,
            pinLongitude: ey11.Longitude,
            shopid: ey11.ShopID,
            shopname: ey11.ShopName,
        };
        return dataShopsY11.push(Y11);
    });

    const dataShopsY12 = []; // Data Y12
    const saleShopY12s = dataAll.length === 0 ? 0 : saleShopY12.map((ey12, index) => {
        let Y12 = {
            key: index,
            dist: ey12.dist_name,
            pinLatitude: `${ey12.Latitude},${ey12.Longitude}`,
            pinLongitude: ey12.Longitude,
            shopid: ey12.ShopID,
            shopname: ey12.ShopName,
        };
        return dataShopsY12.push(Y12);
    });

    const dataShopsY13 = []; // Data Y13
    const saleShopY13s = dataAll.length === 0 ? 0 : saleShopY13.map((ey13, index) => {
        let Y13 = {
            key: index,
            dist: ey13.dist_name,
            pinLatitude: `${ey13.Latitude},${ey13.Longitude}`,
            pinLongitude: ey13.Longitude,
            shopid: ey13.ShopID,
            shopname: ey13.ShopName,
        };
        return dataShopsY13.push(Y13);
    });

    function TabPanelY(props) {



        const { children, valueY, indexY, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={valueY !== indexY}
                id={`full-width-tabpanel-${indexY}`}
                aria-labelledby={`full-width-tab-${indexY}`}
                {...other}
            >
                {valueY === indexY && (
                    <Box p={0}>
                        {children}
                        {/* <Typography component={'div'}>{children}</Typography> */}
                    </Box>
                )}
            </div>
        );
    }

    const columns = [ // Table Tab Dist
        {
            title: 'ดิสทริบิวเตอร์',
            dataIndex: 'dist',
            ellipsis: true,
            width: 40,
            render: (text, record) => (

                <div>
                    {/* <a type="button" href="/#" onClick={() => onChangeDistClick(record.dist)}>&nbsp;{`${(langage === "th") ? record.dist : ChangeNameDist(record.dist)}`}</a> */}
                    {record.dist}
                </div>

            ),
        },
        {
            title: 'จำนวนร้าน',
            dataIndex: 'countShop',
            sorter: (a, b) => a.countShopProvince - b.countShopProvince,
            width: 20
        },
        {
            title: 'จังหวัด',
            dataIndex: 'countProvince',
            defaultSortOrder: 'descend',
            sorter: (a, b) => a.countProvince - b.countProvince,
            width: 20
        },

    ];


    const getDistName = dataAll.length === 0 ? 0 : getUniqueListBy(dataAll, 'DIST_NAME')
    const getProvinceName = dataAll.length === 0 ? 0 : getUniqueListBy(dataAll, 'Province')
    const data = []; // Data table Dist
    const dataDistUnique = dataAll.length === 0 ? 0 : getDistName.map((es, index) => {
        let obj = {
            key: index,
            dist: es.DIST_NAME,
            province: es.Province,
            countShop: ([
                currencyFormat(dataAll.length === 0 ? 0 : dataAll.filter(evs => evs.DIST_NAME === es.DIST_NAME).length)
            ]),
            countProvince: ([
                currencyFormat(dataAll.length === 0 ? 0 : getProvinceName.filter(evs => evs.DIST_NAME === es.DIST_NAME).length)
            ]),
        };
        return data.push(obj);
    });



    // start follow

    function TabPanelF(props) {

        const { children, valueF, indexF, ...other } = props;

        return (
            <div
                role="tabpanel"
                hidden={valueF !== indexF}
                id={`full-width-tabpanel-${indexF}`}
                aria-labelledby={`full-width-tab-${indexF}`}
                {...other}
            >
                {valueF === indexF && (
                    <Box >
                        {/* <Typography component={'div'}>{children}</Typography> */}
                        {children}
                    </Box>
                )}
            </div>
        );
    }

    const columnsFollow = [ // Head Table Follows
        {

            title: 'ที่ตั้ง', //Tablae Y0
            dataIndex: 'pinshopid',
            ellipsis: true,
            render: (text, record) => (

                (record.pinLongitude === null) ? "" :
                    <div>
                        {/* <a type="button" href="#transaction" onClick={() => setTimePin(record.shopid)}>{record.shopid === pinMap ? <img src='./images/focus.png' width="20%" alt={record.dist} /> : <img src={photoPoint(record.dist)} width="50%" alt={record.dist} />}</a> */}
                        <a type="button" href="#transaction" onClick={() => setTimePin(record.shopid)}>{record.shopid === firstids && pinMap === '' ? <img src='./images/point-8.png' width="25px" alt={record.dist} /> : record.shopid === pinMap ? <img src='./images/point-8.png' width="50%" alt={record.dist} /> : ''}</a>
                    </div>
            ),
            width: 80,
            // fixed: 'left',
        },
        {

            title: 'รหัสร้าน', //Tablae Y0
            dataIndex: 'shopid',
            ellipsis: true,
            render: (text, record) => (

                // <Link to={`/profile?id=${record.shopid}`} target="_blank">{record.shopid}</Link>
                <a type="button" href="#transaction" onClick={() => setTimePin(record.shopid)}>{record.shopid}</a>
            ),
        },
        // {
        //     title: intl.formatMessage({ id: 'shopcode' }),
        //     dataIndex: 'shopid',
        //     ellipsis: true,
        //     render: (text, record) => (
        //         <Link to={`/profile?id=${record.shopid}`} target="_blank">{record.shopid}</Link>
        //     ),
        // },
        // {
        //     title: intl.formatMessage({ id: 'shopname' }),
        //     dataIndex: 'shopname',
        //     ellipsis: true,
        // },
        {

            title: 'ชื่อร้านค้า',
            dataIndex: 'shopname',
            ellipsis: true,
            // key: 'shopname'

            render: (text, record) => (

                // <Link to={`/profile?id=${record.shopid}`} target="_blank">{record.shopid}</Link>
                <Link to={`/profile?id=${record.shopid}`} target="_blank">{record.shopname}</Link>

            ),
        },
        {
            title: 'จำนวนลูกค้า',
            dataIndex: 'follows',
            sorter: (a, b) => a.follows - b.follows,
            // width: 80,
            // fixed: 'right',
        },
    ];

    const datasfollow10 = []; //Detail Table Follows 10 
    const follow10s = dataAll.length === 0 ? 0 : follow10.map(eve => {
        let follow10 = {
            key: eve.ShopID,
            shopid: eve.ShopID,
            shopname: eve.ShopName,
            follows: eve.countfollow,
            pinLatitude: `${eve.Latitude},${eve.Longitude}`,
            pinLongitude: eve.Longitude,
        };
        return datasfollow10.push(follow10);
    });

    const datasfollow50 = []; //Detail Table Follows 50
    const follow50s = dataAll.length === 0 ? 0 : follow50.map(eve => {
        let follow50 = {
            key: eve.ShopID,
            shopid: eve.ShopID,
            shopname: eve.ShopName,
            follows: eve.countfollow,
        };
        return datasfollow50.push(follow50);
    });

    const datasfollow100 = []; //Detail Table Follows 100
    const follow100s = dataAll.length === 0 ? 0 : follow100.map(eve => {
        let follow100 = {
            key: eve.ShopID,
            shopid: eve.ShopID,
            shopname: eve.ShopName,
            follows: eve.countfollow,
        };
        return datasfollow100.push(follow100);
    });

    const datasfollow150 = []; //Detail Table Follows 150
    const follow150s = dataAll.length === 0 ? 0 : follow150.map(eve => {
        let follow150 = {
            key: eve.ShopID,
            shopid: eve.ShopID,
            shopname: eve.ShopName,
            follows: eve.countfollow,
        };
        return datasfollow150.push(follow150);
    });

    const datasfollow151 = []; //Detail Table Follows 151
    const follow151s = dataAll.length === 0 ? 0 : follow151.map(eve => {
        let follow151 = {
            key: eve.ShopID,
            shopid: eve.ShopID,
            shopname: eve.ShopName,
            follows: eve.countfollow,
        };
        return datasfollow151.push(follow151);
    });
    // end follow


    const parkData = [];  // Detail Google Map
    const parkDatas = dataAll.length === 0 ? 0 : dataShop.map(e => {
        let objmap = {

            type: "Feature",
            properties: {
                PARK_ID: e.ShopID,
                ShopId: e.ShopID,
                ShopName: e.ShopName,
                ShopDist: e.DIST_NAME,
                ShopType: e.ShopTypeName,
                ShopRegion: e.Region,
                ShopProvince: e.Province,
            },
            geometry: {
                type: "Point",
                coordinates: [
                    parseFloat(e.Longitude),
                    parseFloat(e.Latitude)
                ]
            }
        };
        return parkData.push(objmap);
    });
    
    const mapShopid = dataAll.length === 0 ? 0 : dataShop.map(items => items.ShopID)
    const handleRedirect = () => { // Redirect page from Pin map

        window.open(`/profile?id=${mapShopid[0]}`, '_blank');
    }

    return (
        <div id="transaction">
            <div className="card">
                <div className="card-header">จำนวนร้าน</div>
                <div className="card-body">
                    <div className="row g-2">
                        <div className="col-12 col-md-6">
                            <div className="border">
                                <div className="box-dist-map">
                                    <LoadScript
                                        googleMapsApiKey={`${process.env.REACT_APP_GOOGLE_KEY}`}
                                    >

                                        {/* {

                                            subdetailLayout === true || InputLayout === true || TotalLayout === true || radioLayout === true || regionLayout === true || qualityLayout === true ?
                                                <div className='save-load-box' style={{ height: '953px' }}>
                                                    <CircularProgress size={14} />&nbsp;Loading...
                                                </div> : <> */}
                                        <div className="box-maps" >
                                            {

                                                    <GoogleMap

                                                        mapContainerStyle={containerStyle}
                                                        center={center}
                                                        zoom={6}
                                                        options={options}

                                                    >

                                                        {parkData.map((park, index) => (
                                                            <Marker
                                                                animation={animationPoint}
                                                                key={index}
                                                                onMouseOver={() => setSelectedPark(park)}
                                                                onMouseOut={() => setSelectedPark(null)}
                                                                onClick={() => {
                                                                    handleRedirect()
                                                                    // setSelectedPark(park);
                                                                }}
                                                                position={{
                                                                    lat: park.geometry.coordinates[1],
                                                                    lng: park.geometry.coordinates[0]
                                                                }}

                                                                icon={{
                                                                    // url: "/images/point-blue.png",
                                                                    // url: photoPoint(park.properties.ShopDist)
                                                                    url: './images/point-8.png'
                                                                    // scaledSize: new window.google.maps.Size(100, 100)
                                                                }}
                                                            />
                                                        ))}

                                                        {selectedPark && (
                                                            <InfoWindowF 
                                                                // onCloseClick={() => {
                                                                //     setSelectedPark(null)

                                                                // }}
                                                                onCloseClick={() => {
                                                                    setSelectedPark(null)
                                                                }}
                                                                position={{
                                                                    lat: selectedPark.geometry.coordinates[1] + 0.8,
                                                                    lng: selectedPark.geometry.coordinates[0]
                                                                }}

                                                            >
                                                                <div className="popupmap">
                                                                    {/* <div className="popupphoto"> */}
                                                                    {/* <img src={selectedPark.properties.ShopPhoto} width="20%" alt="" /> */}
                                                                    {/* <br /> */}

                                                                    {/* <a href={`/profile?id=${selectedPark.properties.ShopId}`} target="_blank" rel="noreferrer"><SearchOutlined /> เพิ่มเติม </a> */}

                                                                    {/* </div> */}
                                                                    <div className="popupdetail">
                                                                        รหัสร้าน: <strong style={{ color: '#1890ff' }}>{selectedPark.properties.ShopId}</strong><br />
                                                                        ชื่อร้าน: <strong style={{ color: '#1890ff' }}>{selectedPark.properties.ShopName}</strong><br />
                                                                        ประเภท: <strong style={{ color: '#1890ff' }}>{selectedPark.properties.ShopType}</strong><br />
                                                                        สังกัด:<strong style={{ color: '#1890ff' }}>{selectedPark.properties.ShopDist}</strong><br />
                                                                        ภาค: <strong style={{ color: '#1890ff' }}>{selectedPark.properties.ShopRegion}</strong><br />
                                                                        จังหวัด: <strong style={{ color: '#1890ff' }}>{selectedPark.properties.ShopProvince}</strong><br />
                                                                        {/* เบอร์ติดต่อ:<strong>{selectedPark.properties.ShopTel}</strong> */}
                                                                    </div>
                                                                </div>
                                                            </InfoWindowF>
                                                        )}
                                                    </GoogleMap>
                                            }
                                        </div>
                                        {/* </>
                                        } */}
                                    </LoadScript>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="border" style={{ padding: 0 }}>
                                <div >
                                    <div className="card-header d-flex justify-content-end gap-1">
                                        {(value === 0) ? <Button id="Button1" shape="round" type="primary" className="size-button">จำนวนร้าน</Button> : <Button shape="round" onClick={onhandleTabsShop} className="size-button">จำนวนร้าน</Button>}
                                        {" "}
                                        {(value === 1) ? <Button shape="round" type="primary" className="size-button">ดิสทริบิวเตอร์</Button> : <Button shape="round" onClick={onhandleTabsDist} className="size-button">ดิสทริบิวเตอร์</Button>}
                                        {" "}
                                        {(value === 2) ? <Button shape="round" type="primary" className="size-button">ลูกค้าของร้าน</Button> : <Button shape="round" onClick={onhandleTabsShopFollow} className="size-button">ลูกค้าของร้าน</Button>}
                                    </div>
                                    <TabPanel value={value} index={0} >
                                        {

                                            <>
                                                <div className="d-flex justify-content-center flex-wrap gap-1 pb-4">
                                                    {(saleShopY0.length === 0) ? <Button shape="circle" type="primary" disabled>y0</Button> : (valueY === 1) ? <Button shape="circle" type="primary" >y0</Button> : <Button shape="circle" onClick={() => setValueYY(1)}>y0</Button>}
                                                    {(saleShopY1.length === 0) ? <Button shape="circle" type="primary" disabled>y1</Button> : (valueY === 2) ? <Button shape="circle" type="primary" >y1</Button> : <Button shape="circle" onClick={() => setValueYY(2)}>y1</Button>}
                                                    {(saleShopY2.length === 0) ? <Button shape="circle" type="primary" disabled>y2</Button> : (valueY === 3) ? <Button shape="circle" type="primary" >y2</Button> : <Button shape="circle" onClick={() => setValueYY(3)}>y2</Button>}
                                                    {(saleShopY3.length === 0) ? <Button shape="circle" type="primary" disabled>y3</Button> : (valueY === 4) ? <Button shape="circle" type="primary" >y3</Button> : <Button shape="circle" onClick={() => setValueYY(4)}>y3</Button>}
                                                    {(saleShopY4.length === 0) ? <Button shape="circle" type="primary" disabled>y4</Button> : (valueY === 5) ? <Button shape="circle" type="primary" >y4</Button> : <Button shape="circle" onClick={() => setValueYY(5)}>y4</Button>}
                                                    {(saleShopY5.length === 0) ? <Button shape="circle" type="primary" disabled>y5</Button> : (valueY === 6) ? <Button shape="circle" type="primary" >y5</Button> : <Button shape="circle" onClick={() => setValueYY(6)}>y5</Button>}
                                                    {(saleShopY6.length === 0) ? <Button shape="circle" type="primary" disabled>y6</Button> : (valueY === 7) ? <Button shape="circle" type="primary" >y6</Button> : <Button shape="circle" onClick={() => setValueYY(7)}>y6</Button>}
                                                    {(saleShopY7.length === 0) ? <Button shape="circle" type="primary" disabled>y7</Button> : (valueY === 8) ? <Button shape="circle" type="primary" >y7</Button> : <Button shape="circle" onClick={() => setValueYY(8)}>y7</Button>}
                                                    {(saleShopY8.length === 0) ? <Button shape="circle" type="primary" disabled>y8</Button> : (valueY === 9) ? <Button shape="circle" type="primary" >y8</Button> : <Button shape="circle" onClick={() => setValueYY(9)}>y8</Button>}
                                                    {(saleShopY9.length === 0) ? <Button shape="circle" type="primary" disabled>y9</Button> : (valueY === 10) ? <Button shape="circle" type="primary" >y9</Button> : <Button shape="circle" onClick={() => setValueYY(10)}>y9</Button>}
                                                    {(saleShopY10.length === 0) ? <Button shape="circle" type="primary" disabled>y10</Button> : (valueY === 11) ? <Button shape="circle" type="primary" >y10</Button> : <Button shape="circle" onClick={() => setValueYY(11)}>y10</Button>}
                                                    {(saleShopY11.length === 0) ? <Button shape="circle" type="primary" disabled>y11</Button> : (valueY === 12) ? <Button shape="circle" type="primary" >y11</Button> : <Button shape="circle" onClick={() => setValueYY(12)}>y11</Button>}
                                                    {(saleShopY12.length === 0) ? <Button shape="circle" type="primary" disabled>y12</Button> : (valueY === 13) ? <Button shape="circle" type="primary" >y12</Button> : <Button shape="circle" onClick={() => setValueYY(13)}>y12</Button>}
                                                    {(saleShopY13.length === 0) ? <Button shape="circle" type="primary" disabled>y&gt;13</Button> : (valueY === 14) ? <Button shape="circle" type="primary" >y&gt;13</Button> : <Button shape="circle" onClick={() => setValueYY(14)}>y&gt;13</Button>}
                                                </div>

                                                <TabPanelY valueY={valueY} indexY={1} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY0.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShops}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"

                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={2} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY1.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY1}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={3} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY2.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY2}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={4} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY3.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY3}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={5} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY4.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY4}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={6} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY5.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY5}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={7} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY6.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY6}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={8} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY7.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY7}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={9} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY8.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY8}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={10} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY9.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY9}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={11} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY10.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY10}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={12} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY11.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY11}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={13} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY12.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY12}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>

                                                <TabPanelY valueY={valueY} indexY={14} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(saleShopY13.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsShop}
                                                        dataSource={dataShopsY13}
                                                        pagination={{
                                                            current: (currentPage === 0) ? 1 : currentPage,
                                                            defaultPageSize: 15,
                                                            showSizeChanger: true,
                                                            pageSizeOptions: ['10', '20', '30'],
                                                        }}
                                                        onChange={handleChange}
                                                        className="table-shop"
                                                    />
                                                </TabPanelY>
                                            </>
                                        }
                                    </TabPanel>

                                    {/* Tab Table Dist */}
                                    <TabPanel value={value} index={1} >
                                        {

                                            <div className="firstcolumn">
                                                <Table
                                                    columns={columns}
                                                    dataSource={data}
                                                    pagination={false}
                                                    className="table-shop"
                                                />
                                            </div>
                                        }
                                    </TabPanel>

                                    <TabPanel value={value} index={2} >
                                        {

                                            <>
                                                <div className="d-flex justify-content-center flex-wrap gap-1 pb-4">
                                                    {(follow10.length === 0) ? <Button shape="round" type="primary" disabled>1-10</Button> : (valueF === 0) ? <Button shape="round" type="primary" >1-10</Button> : <Button shape="round" onClick={() => setValueF(0)}>1-10</Button>}
                                                    {(follow50.length === 0) ? <Button shape="round" type="primary" disabled>11-50</Button> : (valueF === 1) ? <Button shape="round" type="primary" >11-50</Button> : <Button shape="round" onClick={() => setValueF(1)}>11-50</Button>}
                                                    {(follow100.length === 0) ? <Button shape="round" type="primary" disabled>51-100</Button> : (valueF === 2) ? <Button shape="round" type="primary" >51-100</Button> : <Button shape="round" onClick={() => setValueF(2)}>51-100</Button>}
                                                    {(follow150.length === 0) ? <Button shape="round" type="primary" disabled>101-150</Button> : (valueF === 3) ? <Button shape="round" type="primary" >101-150</Button> : <Button shape="round" onClick={() => setValueF(3)}>101-150</Button>}
                                                    {(follow151.length === 0) ? <Button shape="round" type="primary" disabled>มากกว่า 150</Button> : (valueF === 4) ? <Button shape="round" type="primary" >มากกว่า 150</Button> : <Button shape="round" onClick={() => setValueF(4)}>มากกว่า 150</Button>}
                                                </div>

                                                <TabPanelF valueF={valueF} indexF={0} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(follow10.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsFollow}
                                                        dataSource={datasfollow10}
                                                        pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                                                        className="table-shop"
                                                    />
                                                </TabPanelF>
                                                <TabPanelF valueF={valueF} indexF={1} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(follow50.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsFollow}
                                                        dataSource={datasfollow50}
                                                        pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                                                        className="table-shop"
                                                    />
                                                </TabPanelF>
                                                <TabPanelF valueF={valueF} indexF={2} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(follow100.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsFollow}
                                                        dataSource={datasfollow100}
                                                        pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                                                        className="table-shop"
                                                    />
                                                </TabPanelF>
                                                <TabPanelF valueF={valueF} indexF={3} >
                                                <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(follow150.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsFollow}
                                                        dataSource={datasfollow150}
                                                        pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                                                        className="table-shop"
                                                    />
                                                </TabPanelF>
                                                <TabPanelF valueF={valueF} indexF={4} >
                                                    <div className="d-flex justify-content-center pb-2">
                                                        จำนวน&nbsp;<span className='text-primary'>{currencyFormat(follow151.length)}</span>&nbsp;ร้านค้า
                                                    </div>
                                                    <Table
                                                        columns={columnsFollow}
                                                        dataSource={datasfollow151}
                                                        pagination={{ defaultPageSize: 20, showSizeChanger: true, pageSizeOptions: ['10', '20', '30'] }}
                                                        className="table-shop"
                                                    />
                                                </TabPanelF>
                                            </>
                                        }
                                    </TabPanel>

                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default BoxMap