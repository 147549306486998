const ChangeNameBusiness = (event) => {
    switch (event) {
        case "อุปโภค":
            return "Consumer & Consumable Shop";
        case "ร้านอาหาร":
            return "Food & Restaurant";
        case "ร้านเสริมสวย ความงาม":
            return "Beauty Salon";
        case "ร้านขายยา":
            return "Pharmacy";
        case "ร้านแม่และเด็ก":
            return "Mom & Kids";
        case "ร้านค้าอื่นๆ":
            return "Others shops";
        default:
            break;
    }
}

const ChangeNameDist = (event) => {
    switch (event) {
        case "บจก.เล้งเส็ง":
            return "Lengseng";
        case "หจก. เอ อาร์ ซี นอร์ทอีสเทิร์น":
            return "ARC North Eastern";
        case "บจก.เอ็กเซ้งเซลส์":
            return "Exeng Sales";
        case "บจก.เอ็ม พรอสเปอร์":
            return "M Prosper";
        case "บจก.เอส แอนด์ เอส ดิสทริบิวชั่น":
            return "S&S Distribution";
        case "บจก.พีเอ็นเค กรุ๊ป":
            return "PNK Group";
        case "บจก.ย่งเส็ง ดิสทริบิวเตอร์":
            return "Yongseng Distributor";
        case "บจก.รุ่งโรจน์ดิสทริบิวชั่น":
            return "Rungroj Distribution";
        case "หจก.สมิสวัน":
            return "Smith One";
        case "บจก.สยาม ฟู้ดแอนด์คอนซูเมอร์":
            return "Siam Food";
        case "หจก.อัศวราชันย์":
            return "Asawarachan";
        case "บจก.อาร์.เอส.เอ็ม. กรุ๊ป":
            return "RSM Group";
        case "อุบล ดิสทริบิวชั่น":
            return "Ubon AS&D";
        case "ดนวัต แดรี่โปรดักส์":
            return "Donawat";
        case "พี.เอส.เจ เทรดดิ้ง":
            return "PSJ Trading";
        default:
            break;
    }
}

const ChangeNameENType = (event) => {
    switch (event) {
        case '1':
            return "Consumer & Consumable Shop";
        case '2':
            return "Food & Restaurant";
        case '3':
            return "Beauty Salon";
        case '4':
            return "Pharmacy";
        case '5':
            return "Mom & Kids";
        case '6':
            return "Others shops";
        default:
            break;
    }
}

const ChangeNameType = (event) => {
    switch (event) {
        case '1':
            return "ร้านสินค้า อุปโภค-บริโภค";
        case '2':
            return "ร้านอาหาร";
        case '3':
            return "ร้านเสริมสวย ความงาม";
        case '4':
            return "ร้านขายยา";
        case '5':
            return "ร้านแม่และเด็ก";
        case '6':
            return "ร้านค้าอื่นๆ";
        default:
            break;
    }
}

const ChangeNameTypeEN = (event) => {
    switch (event) {
        case "1.1 ไฮเปอร์มาร์เก็ต":
            return "Hypermarket";
        case "1.10 เซลส์ดิสทริบิวเตอร์":
            return "Distributor's sales";
        case "1.2 ซุปเปอร์มาร์เก็ต":
            return "Supermarket";
        case "1.3 ร้านค้าส่ง":
            return "Wholesales";
        case "1.4 มินิมาร์ท":
            return "Minimart";
        case "1.5 ร้านโชห่วย (ขนาด 1 คูหา)":
            return "Mom&Pop shop";
        case "1.6 ร้านโชห่วย(มากกว่า 1 คูหา)":
            return "Mom&Pop shop (>1 house)";
        case "1.7 ร้านค้าส่ง+ปลีก":
            return "Wholesales+Retail";
        case "1.8 แผงร้านค้าในตลาดสด":
            return "Fresh Market";
        case "1.9 ดิสทริบิวเตอร์":
            return "Distributor";
        case "2.1 ภัตตาคาร":
            return "Restaurant";
        case "2.10 แผงอาหารในตลาดสด":
            return "Fresh Market (Food)";
        case "2.2 ร้านอาหารขนาดกลาง":
            return "Mid - Restaurant";
        case "2.3 ร้านอาหารขนาดเล็ก":
            return "Food shops";
        case "2.4 ผับ - บาร์ - เลาจ์":
            return "Pub-Bar-Lounge";
        case "2.5 ร้านกาแฟ - เบเกอรี่":
            return "Coffee Bakery shop";
        case "2.6 ร้านอาหารเพิง":
            return "Food street";
        case "2.7 ร้านอาหารรถเข็น":
            return "Food Cart";
        case "2.8 ร้านเครื่องดื่มรถเข็น":
            return "Beverage Cart";
        case "2.9 ร้านในฟู้ดคอร์ท-โรงอาหาร":
            return "Food Court - Canteen";
        case "3.1 ร้านบิวตี้ซุปเปอร์มาร์เก็ต":
            return "Beauty Supermarket";
        case "3.2 ร้านบิวตี้ค้าส่ง":
            return "Beauty wholesales";
        case "3.3 ร้านบิวตี้ขายปลีก":
            return "Beauty Retail";
        case "3.4 ร้านทำผมผู้หญิง":
            return "Women Salon";
        case "3.5 ร้านตัดผมผู้ชาย":
            return "Men Salon";
        case "3.6 ร้านทำผม ชาย-หญิง":
            return "Salon";
        case "3.7 ร้านขายอุปกรณ์เสริมสวย":
            return "Salon Accessories";
        case "4.1 ร้านขายยาส่ง":
            return "Pharmacy Wholesales";
        case "4.2 ร้านขายยาปลีก":
            return "Pharmacy Retail";
        case "4.5 โรงพยาบาล":
            return "Hospital";
        case "5.1 ร้านสินค้าแม่และเด็ก":
            return "Mom & Kid Shop";
        case "6.1 โรงแรม-รีสอร์ท":
            return "Hotel - Resort";
        case "6.2 ร้านขายของสัตว์เลี้ยง":
            return "Pet shop";
        case "6.4 ร้านซ่อม หรือขายอุปกรณ์รถยนต์":
            return "Car Accessories & Services";
        case "6.5 ร้านเครื่องใช้ไฟฟ้า" : return "Electronic shop";
        case "6.6 ร้านอุปกรณ์ก่อสร้าง" : return "Construction Material";
        case "กรุณาบันทึกข้อมูล" : return "Others shop";
        case "ร้านประเภทอื่นๆ" : return "Others shop2";
        default:
            break;
    }
}

const ChangeNameTypeTH = (event) => {
    switch (event) {
        case "1.1 ไฮเปอร์มาร์เก็ต" : return "ไฮเปอร์มาร์เก็ต";
        case "1.10 เซลส์ดิสทริบิวเตอร์" : return "เซลส์ดิสทริบิวเตอร์";
        case "1.2 ซุปเปอร์มาร์เก็ต" : return "ซุปเปอร์มาร์เก็ต";
        case "1.3 ร้านค้าส่ง" : return "ร้านค้าส่ง";
        case "1.4 มินิมาร์ท" : return "มินิมาร์ท";
        case "1.5 ร้านโชห่วย (ขนาด 1 คูหา)" : return "ร้านโชห่วย (ขนาด 1 คูหา)";
        case "1.6 ร้านโชห่วย(มากกว่า 1 คูหา)" : return "ร้านโชห่วย(มากกว่า 1 คูหา)";
        case "1.7 ร้านค้าส่ง+ปลีก" : return "ร้านค้าส่ง+ปลีก";
        case "1.8 แผงร้านค้าในตลาดสด" : return "แผงร้านค้าในตลาดสด";
        case "1.9 ดิสทริบิวเตอร์" : return "ดิสทริบิวเตอร์";
        case "2.1 ภัตตาคาร" : return "ภัตตาคาร";
        case "2.10 แผงอาหารในตลาดสด" : return "แผงอาหารในตลาดสด";
        case "2.2 ร้านอาหารขนาดกลาง" : return "ร้านอาหารขนาดกลาง";
        case "2.3 ร้านอาหารขนาดเล็ก" : return "ร้านอาหารขนาดเล็ก";
        case "2.4 ผับ - บาร์ - เลาจ์" : return "ผับ - บาร์ - เลาจ์";
        case "2.5 ร้านกาแฟ - เบเกอรี่" : return "ร้านกาแฟ - เบเกอรี่";
        case "2.6 ร้านอาหารเพิง" : return "ร้านอาหารเพิง";
        case "2.7 ร้านอาหารรถเข็น" : return "ร้านอาหารรถเข็น";
        case "2.8 ร้านเครื่องดื่มรถเข็น" : return "ร้านเครื่องดื่มรถเข็น";
        case "2.9 ร้านในฟู้ดคอร์ท-โรงอาหาร" : return "ร้านในฟู้ดคอร์ท-โรงอาหาร";
        case "3.1 ร้านบิวตี้ซุปเปอร์มาร์เก็ต" : return "ร้านบิวตี้ซุปเปอร์มาร์เก็ต";
        case "3.2 ร้านบิวตี้ค้าส่ง" : return "ร้านบิวตี้ค้าส่ง";
        case "3.3 ร้านบิวตี้ขายปลีก" : return "ร้านบิวตี้ขายปลีก";
        case "3.4 ร้านทำผมผู้หญิง" : return "ร้านทำผมผู้หญิง";
        case "3.5 ร้านตัดผมผู้ชาย" : return "ร้านตัดผมผู้ชาย";
        case "3.6 ร้านทำผม ชาย-หญิง" : return "ร้านทำผม ชาย-หญิง";
        case "3.7 ร้านขายอุปกรณ์เสริมสวย" : return "ร้านขายอุปกรณ์เสริมสวย";
        case "4.1 ร้านขายยาส่ง" : return "ร้านขายยาส่ง";
        case "4.2 ร้านขายยาปลีก" : return "ร้านขายยาปลีก";
        case "4.5 โรงพยาบาล" : return "โรงพยาบาล";
        case "5.1 ร้านสินค้าแม่และเด็ก" : return "ร้านสินค้าแม่และเด็ก";
        case "6.1 โรงแรม-รีสอร์ท" : return "โรงแรม-รีสอร์ท";
        case "6.2 ร้านขายของสัตว์เลี้ยง" : return "ร้านขายของสัตว์เลี้ยง";
        case "6.4 ร้านซ่อม หรือขายอุปกรณ์รถยนต์" : return "ร้านซ่อม หรือขายอุปกรณ์รถยนต์";
        case "6.5 ร้านเครื่องใช้ไฟฟ้า" : return "ร้านเครื่องใช้ไฟฟ้า";
        case "6.6 ร้านอุปกรณ์ก่อสร้าง" : return "ร้านอุปกรณ์ก่อสร้าง";
        case "กรุณาบันทึกข้อมูล" : return "กรุณาบันทึกข้อมูล";
        case "ร้านประเภทอื่นๆ" : return "ร้านประเภทอื่นๆ";
        default:
            break;
    }
}


const ChangeGenderEN = (event) => {
    switch (event) {
        case null : return "Unspecified";
        case 'ชาย' : return "Male";
        case 'หญิง' : return "Female";
        default : break;
    }
}
const ChangeGenderTH = (event) => {
    switch (event) {
        case null : return "ไม่ระบุ";
        case 'ชาย' : return "ชาย";
        case 'หญิง' : return "หญิง";
        default : break;
    }
}
const ChangeAgeEN = (event) => {
    switch (event) {
        case null : return "Unspecified";
        case '0-15 ปี' : return "0-15 year";
        case '16-20 ปี' : return "16-20 year";
        case '21-30 ปี' : return "21-30 year";
        case '31-40 ปี' : return "31-40 year";
        case '41-50 ปี' : return "41-50 year";
        case '51-60 ปี' : return "51-60 year";
        case '61 ปีขึ้นไป' : return "61+";
        default : break;
    }
}
const ChangeAgeTH = (event) => {
    switch (event) {
        case null : return "ไม่ระบุ";
        case '0-15 ปี' : return "0-15 ปี";
        case '16-20 ปี' : return "16-20 ปี";
        case '21-30 ปี' : return "21-30 ปี";
        case '31-40 ปี' : return "31-40 ปี";
        case '41-50 ปี' : return "41-50 ปี";
        case '51-60 ปี' : return "51-60 ปี";
        case '61 ปีขึ้นไป' : return "61 ปีขึ้นไป";
        default : break;
    }
}

const ChangeProvince = (event) => {
    switch (event) {
        case 'กำแพงเพชร' : return 'Kamphaeng Phet';
        case 'เชียงราย' : return 'Chiang Rai';
        case 'เชียงใหม่' : return 'Chiang Mai';
        case 'ตาก' : return 'Tak';
        case 'นครสวรรค์' : return 'Nakhon Sawan';
        case 'น่าน' : return 'Nan';
        case 'พิจิตร' : return 'Phichit';
        case 'พิษณุโลก' : return 'Phitsanulok';
        case 'เพชรบูรณ์' : return 'Phetchabun';
        case 'แพร่' : return 'Phrae';
        case 'แม่ฮ่องสอน' : return 'Mae Hong Son';
        case 'ลำปาง' : return 'Lampang';
        case 'ลำพูน' : return 'Lamphun';
        case 'สุโขทัย' : return 'Sukhothai';
        case 'อุตรดิตถ์' : return 'Uttaradit';
        case 'อุทัยธานี' : return 'Uthai Thani';
        case 'พะเยา' : return 'Phayao';
        case 'กรุงเทพมหานคร' : return 'Bangkok';
        case 'กาญจนบุรี' : return 'Kanchanaburi';
        case 'จันทบุรี' : return 'Chanthaburi';
        case 'ฉะเชิงเทรา' : return 'Chachoengsao';
        case 'ชลบุรี' : return 'Chon Buri';
        case 'ชัยนาท' : return 'Chai Nat';
        case 'ตราด' : return 'Trat';
        case 'นครนายก' : return 'Nakhon Nayok';
        case 'นครปฐม' : return 'Nakhon Pathom';
        case 'นนทบุรี' : return 'Nonthaburi';
        case 'ปทุมธานี' : return 'Pathum Thani';
        case 'ประจวบคีรีขันธ์' : return 'Prachuap Khiri Khan';
        case 'ปราจีนบุรี' : return 'Prachin Buri';
        case 'พระนครศรีอยุธยา' : return 'Phra Nakhon Si Ayutthaya';
        case 'เพชรบุรี' : return 'Phetchaburi';
        case 'ระยอง' : return 'Rayong';
        case 'ราชบุรี' : return 'Ratchaburi';
        case 'ลพบุรี' : return 'Lop Buri';
        case 'สมุทรปราการ' : return 'Samut Prakan';
        case 'สมุทรสงคราม' : return 'Samut Songkhram';
        case 'สมุทรสาคร' : return 'Samut Sakhon';
        case 'สระบุรี' : return 'Saraburi';
        case 'สิงห์บุรี' : return 'Sing Buri';
        case 'สุพรรณบุรี': return 'Suphan Buri';
        case 'อ่างทอง' : return 'Ang Thong';
        case 'สระแก้ว' : return 'Sa Kaeo';
        case 'กาฬสินธุ์' : return 'Kalasin';
        case 'ขอนแก่น' : return 'Khon Kaen';
        case 'ชัยภูมิ' : return 'Chaiyaphum';
        case 'ยโสธร' : return 'Yasothon';
        case 'นครพนม' : return 'Nakhon Phanom';
        case 'นครราชสีมา' : return 'Nakhon Ratchasima';
        case 'บุรีรัมย์' : return 'Buri Ram';
        case 'มหาสารคาม' : return 'Maha Sarakham';
        case 'ร้อยเอ็ด' : return 'Roi Et';
        case 'เลย' : return 'Loei';
        case 'ศรีสะเกษ' : return 'Si Sa Ket';
        case 'สกลนคร' : return 'Sakon Nakhon';
        case 'สุรินทร์' : return 'Surin';
        case 'หนองคาย' : return 'Nong Khai';
        case 'อุดรธานี' : return 'Udon Thani';
        case 'อุบลราชธานี' : return 'Ubon Ratchathani';
        case 'มุกดาหาร' : return 'Mukdahan';
        case 'อำนาจเจริญ' : return 'Amnat Charoen';
        case 'หนองบัวลำภู' : return 'Nong Bua Lam Phu';
        case 'กระบี่' : return 'Krabi';
        case 'ชุมพร' : return 'Chumphon';
        case 'ตรัง' : return 'Trang';
        case 'นครศรีธรรมราช' : return 'Nakhon Si Thammarat';
        case 'นราธิวาส' : return 'Narathiwat';
        case 'ปัตตานี' : return 'Pattani';
        case 'พังงา' : return 'Phangnga';
        case 'พัทลุง' : return 'Phatthalung';
        case 'ภูเก็ต' : return 'Phuket';
        case 'ยะลา' : return 'Yala';
        case 'ระนอง' : return 'Ranong';
        case 'สงขลา' : return 'Songkhla';
        case 'สตูล' : return 'Satun';
        case 'สุราษฎร์ธานี': return 'Surat Thani';
        case 'บึงกาฬ' : return 'Bueng Kan';
        case 'ทั้งหมด' : return "All";
        default : break;
    }
}
const ChangeProvinceId = (event) => {
    switch (event) {
        case '10' : return 'กรุงเทพมหานคร';
        case '11' : return 'สมุทรปราการ';
        case '12' : return 'นนทบุรี';
        case '13' : return 'ปทุมธานี';
        case '14' : return 'พระนครศรีอยุธยา';
        case '15' : return 'อ่างทอง';
        case '16' : return 'ลพบุรี';
        case '17' : return 'สิงห์บุรี';
        case '18' : return 'ชัยนาท';
        case '19' : return 'สระบุรี';
        case '20' : return 'ชลบุรี';
        case '21' : return 'ระยอง';
        case '22' : return 'จันทบุรี';
        case '23' : return 'ตราด';
        case '24' : return 'ฉะเชิงเทรา';
        case '25' : return 'ปราจีนบุรี';
        case '26' : return 'นครนายก';
        case '27' : return 'สระแก้ว';
        case '30' : return 'นครราชสีมา';
        case '31' : return 'บุรีรัมย์';
        case '32' : return 'สุรินทร์';
        case '33' : return 'ศรีสะเกษ';
        case '34' : return 'อุบลราชธานี';
        case '35' : return 'ยโสธร';
        case '36' : return 'ชัยภูมิ';
        case '37' : return 'อำนาจเจริญ';
        case '38' : return 'บึงกาฬ';
        case '39' : return 'หนองบัวลำภู';
        case '40' : return 'ขอนแก่น';
        case '41' : return 'อุดรธานี';
        case '42' : return 'เลย';
        case '43' : return 'หนองคาย';
        case '44' : return 'มหาสารคาม';
        case '45' : return 'ร้อยเอ็ด';
        case '46' : return 'กาฬสินธุ์';
        case '47' : return 'สกลนคร';
        case '48' : return 'นครพนม';
        case '49' : return 'มุกดาหาร';
        case '50' : return 'เชียงใหม่';
        case '51' : return 'ลำพูน';
        case '52' : return 'ลำปาง';
        case '53' : return 'อุตรดิตถ์';
        case '54' : return 'แพร่';
        case '55' : return 'น่าน';
        case '56' : return 'พะเยา';
        case '57' : return 'เชียงราย';
        case '58' : return 'แม่ฮ่องสอน';
        case '60' : return 'นครสวรรค์';
        case '61' : return 'อุทัยธานี';
        case '62' : return 'กำแพงเพชร';
        case '63' : return 'ตาก';
        case '64' : return 'สุโขทัย';
        case '65' : return 'พิษณุโลก';
        case '66' : return 'พิจิตร';
        case '67' : return 'เพชรบูรณ์';
        case '70' : return 'ราชบุรี';
        case '71' : return 'กาญจนบุรี';
        case '72' : return 'สุพรรณบุรี';
        case '73' : return 'นครปฐม';
        case '74' : return 'สมุทรสาคร';
        case '75' : return 'สมุทรสงคราม';
        case '76' : return 'เพชรบุรี';
        case '77' : return 'ประจวบคีรีขันธ์';
        case '80' : return 'นครศรีธรรมราช';
        case '81' : return 'กระบี่';
        case '82' : return 'พังงา';
        case '83' : return 'ภูเก็ต';
        case '84' : return 'สุราษฎร์ธานี';
        case '85' : return 'ระนอง';
        case '86' : return 'ชุมพร';
        case '90' : return 'สงขลา';
        case '91' : return 'สตูล';
        case '92' : return 'ตรัง';
        case '93' : return 'พัทลุง';
        case '94' : return 'ปัตตานี';
        case '95' : return 'ยะลา';
        case '96' : return 'นราธิวาส';
        default : break;
    }
}
const ChangeRegionId = (event) => {
    switch (event) {
        case 1 : return 'ภาคเหนือ';
        case 2 : return 'ภาคใต้';
        case 3 : return 'ภาคกลาง';
        case 4 : return 'ภาคตะวันตก';
        case 5 : return 'ภาคตะวันออก';
        case 6 : return 'ภาคตะวันออกเฉียงเหนือ';
        default : break;
    }
}
const ChangeDistId = (event) => {
    switch (event) {
        case '02' : return 'บจก.เล้งเส็ง';
        case '03' : return 'หจก. เอ อาร์ ซี นอร์ทอีสเทิร์น';
        case '05' : return 'บจก.เอ็กเซ้งเซลส์';
        case '06' : return 'บจก.เอ็ม พรอสเปอร์';
        case '07' : return 'บจก. เอสแอนด์ เอส โอเอ็มซี';
        case '08' : return 'บจก.พีเอ็นเค กรุ๊ป';
        case '09' : return 'บจก.ย่งเส็ง ดิสทริบิวเตอร์';
        case '10' : return 'บจก.รุ่งโรจน์ดิสทริบิวชั่น';
        case '11' : return 'หจก.สมิสวัน';
        case '12' : return 'บจก.สยาม ฟู้ดแอนด์คอนซูเมอร์';
        case '13' : return 'หจก.อัศวราชันย์';
        case '14' : return 'บจก.อาร์.เอส.เอ็ม. กรุ๊ป';
        case '15' : return 'อุบล ดิสทริบิวชั่น';
        case '16' : return 'ดนวัต แดรี่โปรดักส์';
        case '17' : return 'พี.เอส.เจ เทรดดิ้ง';
        default : break;
    }
}
const ChangeShopTypeId = (event) => {
    switch (event) {
        case '101' : return '1.1 ไฮเปอร์มาร์เก็ต';
        case '102' : return '1.2 ซุปเปอร์มาร์เก็ต';
        case '103' : return '1.3 ร้านค้าส่ง';
        case '104' : return '1.4 มินิมาร์ท';
        case '105' : return '1.5 ร้านโชห่วย (ขนาด 1 คูหา)';
        case '106' : return '1.6 ร้านโชห่วย(มากกว่า 1 คูหา)';
        case '107' : return '1.7 ร้านค้าส่ง+ปลีก';
        case '108' : return '1.8 แผงร้านค้าในตลาดสด';
        case '109' : return '1.9 ดิสทริบิวเตอร์';
        case '110' : return '1.10 เซลส์ดิสทริบิวเตอร์';
        case '201' : return '2.1 ภัตตาคาร';
        case '202' : return '2.2 ร้านอาหารขนาดกลาง';
        case '203' : return '2.3 ร้านอาหารขนาดเล็ก';
        case '204' : return '2.4 ผับ - บาร์ - เลาจ์';
        case '205' : return '2.5 ร้านกาแฟ - เบเกอรี่';
        case '206' : return '2.6 ร้านอาหารเพิง';
        case '207' : return '2.7 ร้านอาหารรถเข็น';
        case '208' : return '2.8 ร้านเครื่องดื่มรถเข็น';
        case '209' : return '2.9 ร้านในฟู้ดคอร์ท-โรงอาหาร';
        case '210' : return '2.10 แผงอาหารในตลาดสด';
        case '301' : return '3.1 ร้านบิวตี้ซุปเปอร์มาร์เก็ต';
        case '302' : return '3.2 ร้านบิวตี้ค้าส่ง';
        case '303' : return '3.3 ร้านบิวตี้ขายปลีก';
        case '304' : return '3.4 ร้านทำผมผู้หญิง';
        case '305' : return '3.5 ร้านตัดผมผู้ชาย';
        case '306' : return '3.6 ร้านทำผม ชาย-หญิง';
        case '307' : return '3.7 ร้านขายอุปกรณ์เสริมสวย';
        case '401' : return '4.1 ร้านขายยาส่ง';
        case '402' : return '4.2 ร้านขายยาปลีก';
        case '405' : return '4.5 โรงพยาบาล';
        case '501' : return '5.1 ร้านสินค้าแม่และเด็ก';
        case '601' : return '6.1 โรงแรม-รีสอร์ท';
        case '602' : return '6.2 ร้านขายของสัตว์เลี้ยง';
        case '604' : return '6.4 ร้านซ่อม หรือขายอุปกรณ์รถยนต์';
        case '605' : return '6.5 ร้านเครื่องใช้ไฟฟ้า';
        case '606' : return '6.6 ร้านอุปกรณ์ก่อสร้าง';
        case 'XXX' : return 'กรุณาบันทึกข้อมูล';
        case 'ZT' : return 'ร้านประเภทอื่นๆ';
        default : break;
    }
}
const ChangeShopTypeName = (event) => {
    switch (event) {
        case '101' : return 'ไฮเปอร์มาร์เก็ต';
        case '102' : return 'ซุปเปอร์มาร์เก็ต';
        case '103' : return 'ร้านค้าส่ง';
        case '104' : return 'มินิมาร์ท';
        case '105' : return 'ร้านโชห่วย (ขนาด 1 คูหา)';
        case '106' : return 'ร้านโชห่วย(มากกว่า 1 คูหา)';
        case '107' : return 'ร้านค้าส่ง+ปลีก';
        case '108' : return 'แผงร้านค้าในตลาดสด';
        case '109' : return 'ดิสทริบิวเตอร์';
        case '110' : return 'เซลส์ดิสทริบิวเตอร์';
        case '201' : return 'ภัตตาคาร';
        case '202' : return 'ร้านอาหารขนาดกลาง';
        case '203' : return 'ร้านอาหารขนาดเล็ก';
        case '204' : return 'ผับ - บาร์ - เลาจ์';
        case '205' : return 'ร้านกาแฟ - เบเกอรี่';
        case '206' : return 'ร้านอาหารเพิง';
        case '207' : return 'ร้านอาหารรถเข็น';
        case '208' : return 'ร้านเครื่องดื่มรถเข็น';
        case '209' : return 'ร้านในฟู้ดคอร์ท-โรงอาหาร';
        case '210' : return 'แผงอาหารในตลาดสด';
        case '301' : return 'ร้านบิวตี้ซุปเปอร์มาร์เก็ต';
        case '302' : return 'ร้านบิวตี้ค้าส่ง';
        case '303' : return 'ร้านบิวตี้ขายปลีก';
        case '304' : return 'ร้านทำผมผู้หญิง';
        case '305' : return 'ร้านตัดผมผู้ชาย';
        case '306' : return 'ร้านทำผม ชาย-หญิง';
        case '307' : return 'ร้านขายอุปกรณ์เสริมสวย';
        case '401' : return 'ร้านขายยาส่ง';
        case '402' : return 'ร้านขายยาปลีก';
        case '405' : return 'โรงพยาบาล';
        case '501' : return 'ร้านสินค้าแม่และเด็ก';
        case '601' : return 'โรงแรม-รีสอร์ท';
        case '602' : return 'ร้านขายของสัตว์เลี้ยง';
        case '604' : return 'ร้านซ่อม หรือขายอุปกรณ์รถยนต์';
        case '605' : return 'ร้านเครื่องใช้ไฟฟ้า';
        case '606' : return 'ร้านอุปกรณ์ก่อสร้าง';
        case 'XXX' : return 'กรุณาบันทึกข้อมูล';
        case 'ZT' : return 'ร้านประเภทอื่นๆ';        
        default : break;
    }
}
const ChangeRegion = (event) => {
    switch (event) {
        case 'ภาคตะวันออกเฉียงเหนือ' : return "Northeast";
        case 'ภาคใต้' : return "South";
        case 'ภาคกลาง' : return "Central";
        case 'ภาคเหนือ' : return "North";
        case 'ภาคตะวันตก' : return "West";
        case 'ภาคตะวันออก' : return "East";
        case 'ทั้งหมด' : return "ทั้งหมด";
        case 'All' : return "All";
        default : break;
    }
}
const photoPoint = (name) => {
    switch (name) {
        case "บจก.เอ็ม พรอสเปอร์" : return "/images/point-1.png";
        case "บริษัท ทันใจ ดิสทริบิวชั่น จำกัด" : return "/images/point2.png";
        case "หจก.อัศวราชันย์" : return "/images/point-3.png";
        case "บจก.รุ่งโรจน์ดิสทริบิวชั่น" : return "/images/point-4.png";
        case "ดนวัต แดรี่โปรดักส์" : return "/images/point-5.png";
        case "บจก.อาร์.เอส.เอ็ม. กรุ๊ป" : return "/images/point-6.png";
        case "อุบล ดิสทริบิวชั่น" : return "/images/point-7.png";
        case "บจก.เล้งเส็ง" : return "/images/point-8.png";
        case "หจก. เอ อาร์ ซี นอร์ทอีสเทิร์น" : return "/images/point-9.png";
        case "หจก.สมิสวัน" : return "/images/point-10.png";
        case "บจก.เอส แอนด์ เอส ดิสทริบิวชั่น" : return "/images/point-11.png";
        case "บจก.ย่งเส็ง ดิสทริบิวเตอร์" : return "/images/point-12.png";
        case "บจก.เอ็กเซ้งเซลส์" : return "/images/point-13.png";
        case "บจก.พีเอ็นเค กรุ๊ป" : return "/images/point-14.png";
        case "พี.เอส.เจ เทรดดิ้ง" : return "/images/point-15.png";
        case "บจก.สยาม ฟู้ดแอนด์คอนซูเมอร์" : return "/images/point-16.png";
        default: return "/images/b52logo.jpg";
    }
}


export {
    ChangeNameBusiness,
    ChangeNameDist,
    ChangeNameENType,
    ChangeNameType,
    ChangeNameTypeEN,
    ChangeNameTypeTH,
    ChangeProvince,
    ChangeRegion,
    photoPoint,
    ChangeGenderTH,
    ChangeGenderEN,
    ChangeAgeEN,
    ChangeAgeTH,
    ChangeProvinceId,
    ChangeRegionId,
    ChangeDistId,
    ChangeShopTypeId,
    ChangeShopTypeName

}
